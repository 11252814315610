import React, { useState } from 'react';
import './ModalStyles.scss';

const DropdownComponent = ({ artCat }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    artCat(event.target.value.toLowerCase())
  };

  return (
    <div className='downdropselection'>
      {selectedOption && <h2>{selectedOption}</h2>}
      <select id="categoryDropdown" value={selectedOption} onChange={handleChange}>
        <option value="">Select a Category</option>
        <option value="Coils and Curls">Coils and Curls</option>
        <option value="Food and Hair">Food and Hair</option>
        <option value="Work and Hair">Work and Hair</option>
        <option value="Home Girl">Home Girl</option>
        <option value="Misc">Misc</option>
      </select>
    </div>
  );
};

export default DropdownComponent;

