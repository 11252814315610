import {
  useContext,
  useState,
  useEffect,
  Fragment,
  useRef,
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import ProductCard from '../../components/product-card/product-card.component';
import { CategoriesContext } from '../../contexts/categories.context';
import ReactQuill from 'react-quill';
import CropImage from './cropImage';
import 'react-quill/dist/quill.snow.css';
import './category.styles.scss';
import Add from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { UserContext } from '../../contexts/user.context';
import { useNavigate } from 'react-router-dom';
import Resizer from 'react-image-file-resizer';
import { AddPost, uploadCoverArt } from '../../utils/firebase/firebase.utils';
import { v4 } from 'uuid';
import { Link } from 'react-router-dom';
import ModalOverlay from './overlay';

const Category = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const { category } = useParams();
  const { categoriesMap } = useContext(CategoriesContext);
  const [products, setProducts] = useState(categoriesMap[category]);
  const [categoryName, setCategoryName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [isCropOpen, setIsCropOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const quillRef = useRef(null);
  const [coverArt, setCoverArt] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    const findCat = Object.keys(categoriesMap).find((currCategory) => {
      return currCategory.replace(/ /g, '') === category;
    });
    setCategoryName(findCat);
    setProducts(categoriesMap[findCat]);
  }, [category, categoriesMap]);

  const handleOpenModal = () => {
    if (currentUser) {
      setIsModalOpen(true);
    } else {
      navigate('/auth');
    }
  };

  const resetForm = () => {
    setCategoryName('');
    setIsModalOpen(false);
    setTitle('');
    setContent('');
    setIsCropOpen(false);
    setImageSrc(null);
    setCoverArt(null);

    // If you need to reset the Quill editor content
    if (quillRef.current) {
      quillRef.current.getEditor().setText('');
    }
  };

  const handleCloseModal = () => {
    resetForm();
    setIsModalOpen(false);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
        setIsCropOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCropComplete = (croppedImageUrl) => {
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection();
    quill.insertEmbed(range.index, 'image', croppedImageUrl);
    setIsCropOpen(false);
    setImageSrc(null);
  };

  const handleSubmit = async () => {
    try {
      const downloadURL = await uploadCoverArt(file);
      console.log('File available at', downloadURL);

      if (downloadURL) {
        await AddPost(categoryName, {
          id: v4(),
          writerId: currentUser.id || currentUser.uid,
          title,
          content,
          username:
            currentUser.displayName ||
            currentUser.email.split('@')[0].charAt(0).toUpperCase() +
              currentUser.email.split('@')[0].slice(1).toLowerCase(),
          imageUrl: downloadURL,
        });

        handleCloseModal();
      }
    } catch (error) {
      console.error('Error uploading file or adding post:', error);
    }
  };

  const imageHandler = () => {
    document.getElementById('image-upload').click();
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: '1' }, { header: '2' }, { font: [] }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ align: [] }],
          [{ color: [] }, { background: [] }],
          ['clean'],
        ],
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'color',
    'background',
    'align',
  ];

  // const MAX_SIZE_KB = 15;
  // const INITIAL_WIDTH = 500;
  // const INITIAL_HEIGHT = 500;
  // const INITIAL_QUALITY = 100;
  // const FORMAT = 'WEBP';

  // const resizeFile = (file, width, height, quality) =>
  //   new Promise((resolve) => {
  //     Resizer.imageFileResizer(
  //       file,
  //       width,
  //       height,
  //       FORMAT,
  //       quality,
  //       0,
  //       (uri) => {
  //         fetch(uri)
  //           .then((response) => response.blob())
  //           .then((blob) => resolve({ uri, blob }))
  //           .catch((error) =>
  //             console.error('Error converting URI to Blob:', error)
  //           );
  //       },
  //       'base64'
  //     );
  //   });

  // const compressImageToMaxSize = async (file) => {
  //   let width = INITIAL_WIDTH;
  //   let height = INITIAL_HEIGHT;
  //   let quality = INITIAL_QUALITY;

  //   while (true) {
  //     const { uri, blob } = await resizeFile(file, width, height, quality);
  //     const sizeKB = blob.size / 1024;

  //     if (sizeKB <= MAX_SIZE_KB) {
  //       return { uri, blob };
  //     }

  //     if (quality > 10) {
  //       quality -= 10;
  //     } else if (width > 100 && height > 100) {
  //       width -= 50;
  //       height -= 50;
  //     } else {
  //       console.error(
  //         'Unable to compress image below 10KB while preserving quality.'
  //       );
  //       return { uri: null, blob: null };
  //     }
  //   }
  // };
  const MAX_SIZE_KB = 30;
  const INITIAL_WIDTH = 500;
  const INITIAL_HEIGHT = 500;
  const INITIAL_QUALITY = 100;
  const FORMAT = 'WEBP';
  const MIN_QUALITY = 70; // Minimum quality threshold to avoid extremely poor quality

  const resizeFile = (file, width, height, quality) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        width,
        height,
        FORMAT,
        quality,
        0,
        (uri) => {
          fetch(uri)
            .then((response) => response.blob())
            .then((blob) => resolve({ uri, blob }))
            .catch((error) =>
              console.error('Error converting URI to Blob:', error)
            );
        },
        'base64'
      );
    });

  const compressImageToMaxSize = async (file) => {
    let width = INITIAL_WIDTH;
    let height = INITIAL_HEIGHT;
    let quality = INITIAL_QUALITY;

    while (true) {
      const { uri, blob } = await resizeFile(file, width, height, quality);
      const sizeKB = blob.size / 1024;

      if (sizeKB <= MAX_SIZE_KB) {
        return { uri, blob };
      }

      // Reduce quality until it reaches MIN_QUALITY, then reduce dimensions
      if (quality > MIN_QUALITY) {
        quality -= 5; // Decrease quality in smaller steps
      } else if (width > 100 && height > 100) {
        width -= 20; // Decrease dimensions in smaller steps if quality is too low
        height -= 20;
      } else {
        console.error(
          'Unable to compress image below 15KB while preserving quality above the minimum threshold.'
        );
        return { uri: null, blob: null };
      }
    }
  };

  const handleFileChange = async (event) => {
    let file = event.target.files[0];
    const { uri: fileURI, blob: fileBlob } = await compressImageToMaxSize(file);

    if (fileURI && fileBlob) {
      const reader = new FileReader();
      reader.onload = () => {
        setCoverArt(reader.result);
        setFile(fileBlob);
      };
      reader.readAsDataURL(fileBlob);
    }
  };

  return (
    <Fragment>
      <div className="category-title">
        <Link to="/posts" className="right-link-all">
          All Posts
        </Link>

        <h2>{categoryName?.toUpperCase()}</h2>
        <IconButton onClick={handleOpenModal} className="add-icon">
          <AddIcon
            style={{
              color: '#fff',
            }}
          />
        </IconButton>
      </div>

      {isModalOpen && (
        <ModalOverlay
          categoryName={categoryName}
          title={title}
          handleTitleChange={handleTitleChange}
          coverArt={coverArt}
          handleFileChange={handleFileChange}
          content={content}
          handleContentChange={handleContentChange}
          modules={modules}
          formats={formats}
          quillRef={quillRef}
          handleImageUpload={handleImageUpload}
          handleSubmit={handleSubmit}
          handleCloseModal={handleCloseModal}
        />
      )}
      {isCropOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <CropImage src={imageSrc} onComplete={handleCropComplete} />
            <button
              onClick={() => setIsCropOpen(false)}
              className="modal-close-button"
            >
              X
            </button>
          </div>
        </div>
      )}

      <div className="category-container">
        {products &&
          products.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
      </div>
    </Fragment>
  );
};

export default Category;
