import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './ModalComponent.scss';
import DropdownComponent from '../../components/category-selection/category-selection'

const ModalOverlay = ({
    categoryName,
    setCatName,
    title,
    handleTitleChange,
    coverArt,
    handleFileChange,
    content,
    handleContentChange,
    modules,
    formats,
    quillRef,
    handleImageUpload,
    handleSubmit,
    handleCloseModal,

}) => {
    const [name, setName] = useState(categoryName)
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button onClick={() => handleCloseModal()} className="modal-close-button">
                    X
                </button>
                {categoryName ?
                    <h2>{name?.toUpperCase()}</h2> : <DropdownComponent artCat={setCatName} />
                }
                <input
                    type="text"
                    value={title}
                    onChange={handleTitleChange}
                    placeholder="Post Title"
                    className="modal-input"
                    required={true}
                />
                <div className="cover-image-div">
                    <div className="file-input-with-preview">
                        {coverArt && <h3 className="image-title">Cover Art</h3>}
                        {coverArt && (
                            <img src={coverArt} alt="Preview" className="image-preview" />
                        )}
                        <input type="file" onChange={handleFileChange} required />
                    </div>
                </div>

                <ReactQuill
                    value={content}
                    onChange={handleContentChange}
                    modules={modules}
                    formats={formats}
                    className="modal-textarea"
                    theme="snow"
                    ref={quillRef}
                />

                <input
                    type="file"
                    id="image-upload"
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={handleImageUpload}
                />
                <button
                    onClick={categoryName ? handleSubmit : () => handleSubmit(name)}
                    className="modal-submit-button"
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export default ModalOverlay;
