import { Fragment, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

const TermsOfService = () => {
  return (
    <Fragment>
      <div>
        <hr />
        <h1>Terms of Service for NEWDEW</h1>
        <p>Last Updated: 2024-06-01</p>
        <p>
          These Terms of Service (the "Terms of Service") govern the use of the
          mobile and internet-based services, business tools offered by the
          NEWDEW Inc. (the "Company," "NEWDEW," "we" or "us") at and through the
          website and through mobile applications (such services, applications
          and the Website are collectively referred to as the "Services"). The
          Company's Privacy Policy located at
          https://www.newdew.io/privacypolicy as well as any community
          guidelines or dispute resolution procedures that the Company may
          provide or make available, are all considered part of these Terms of
          Service and are incorporated by reference into these Terms of Service
          in their entirety. Any reference to these Terms of Service shall
          include and mean a reference to all such incorporated policies,
          guidelines, programs, schedules and processes. In the event of a
          conflict between these Terms of Service and any other terms posted on
          the Website, the terms of these Terms of Service shall govern, and in
          the event of a conflict between these Terms of Service and the End
          User License Agreement in mobile applications (the "EULA"), the terms
          of the EULA shall govern.
        </p>
        <p>
          PLEASE READ THESE TERMS OF SERVICE CAREFULLY. THIS IS A LEGAL
          AGREEMENT BETWEEN NEWDEW AND YOU WHICH GOVERNS YOUR USE OF THE
          SERVICES. YOUR USE OF THE SERVICES CONSTITUTES YOUR ACCEPTANCE OF AND
          AGREEMENT TO ALL OF THE TERMS AND CONDITIONS IN THESE TERMS OF SERVICE
          AND YOUR REPRESENTATION THAT YOU ARE 18 YEARS OF AGE OR OLDER. IF YOU
          OBJECT TO ANYTHING IN THESE TERMS OF SERVICE, YOU ARE NOT PERMITTED TO
          USE THE SERVICES.
        </p>
        <p>
          If you are accepting these Terms of Service and using the Services on
          behalf of a company, organization, government, or other legal entity,
          you represent and warrant to NEWDEW that you have full power and
          authority to do so.
        </p>
        <p>
          The Services are protected by copyright laws and international
          copyright treaties, as well as other intellectual property laws and
          treaties. Unauthorized reproduction or distribution of the Services,
          or any portion of them, may result in severe civil and criminal
          penalties, and will be prosecuted to the maximum extent possible under
          the law. The Services are licensed, not sold.
        </p>
        <p>
          <strong>The NEWDEW Services</strong>
        </p>
        <p>
          NEWDEW is a platform that connects the best stylists (collectively or
          individually, "Professionals") providing hair and grooming services
          ("Grooming Services") with clients seeking such services ("Clients" or
          "you"). The Professionals and Clients are both users of the Services
          provided by NEWDEW and are hereinafter referred to collectively as
          "Users."
        </p>
        <p>
          NEWDEW solely provides a platform for Professionals and Clients to
          connect and serves only as a medium to facilitate the provision of
          Grooming Services. NEWDEW does not provide or contract for Grooming
          Services, and Professionals and Clients contract independently for the
          provision of Grooming Services. Each Client is solely responsible for
          selecting the Professional, the Grooming Services to be provided and
          the location at which Grooming Services will be performed, whether on
          the premises of a Professional or at a site designated by the Client.
          Any decision by a Client to receive Grooming Services or by a
          Professional to provide Grooming Services is a decision made in such
          person's sole discretion and at their own risk. All Users understand
          and acknowledge that (i) the Company does not conduct background
          checks on Clients and (ii) any provision of Services in a private
          location inherently increases the risks involved for both Clients and
          Professionals.
        </p>
        <p>
          NEWDEW does not have control over the quality, suitability,
          reliability, timing, durability, legality, failure to provide, or any
          other aspect whatsoever of any Grooming Services provided by
          Professionals nor of the integrity, responsibility or any of the
          actions or omissions whatsoever of any Professionals or Clients.
          NEWDEW makes no representations or warranties whatsoever with respect
          to Grooming Services offered or provided by Professionals or requested
          by Clients through use of the Services, whether in public, private, or
          offline interactions, or about the accreditation, registration or
          licensing of any Professional. You understand that NEWDEW does not
          routinely screen its Users, inquire into the background of its Users
          or attempt to verify information provided by any User. NEWDEW does not
          verify or confirm that any User is who they claim to be or is
          accurately representing themselves and does not verify or confirm any
          representations with respect to Grooming Services on the Services.
          NEWDEW does not assume any responsibility for the accuracy or
          reliability of this information or any information provided through
          the Services. Notwithstanding the foregoing, NEWDEW may, but is not
          obligated to, check the background and experience of Professionals via
          third-party background check services and first party interviews,
          including but not limited to a verification of identity and a
          comprehensive criminal background check, and may terminate a
          Professional based on any information discovered during such
          background checks and first party interviews. Whether or not NEWDEW
          conducts a background check or first party interview on a
          Professional, each Client must decide whether a Professional is suited
          to such Client's needs and should exercise caution and common sense to
          protect their personal safety and property, just as they would when
          interacting with anyone you don't know. NEITHER COMPANY NOR ITS
          AFFILIATES OR LICENSORS IS RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE
          OR OFFLINE, OF ANY USER OF THE SERVICES AND YOU HEREBY RELEASE THE
          COMPANY AND ITS AFFILIATES OR LICENSORS FROM ANY LIABILITY RELATED
          THERETO. COMPANY AND ITS AFFILIATES AND LICENSORS WILL NOT BE LIABLE
          FOR ANY CLAIM, INJURY OR DAMAGE ARISING IN CONNECTION WITH YOUR USE OF
          THE SERVICES.
        </p>
        <p>
          In connection with certain services, including Grooming Services, for
          which reservations are made available on the Services, some
          Professionals may require you to enter credit card or other payment
          information, and such Professionals may charge fees for reservations,
          including cancelled and missed appointments. The Professionals are
          only allowed to do so in accordance with any policy posted on such
          Professional's NEWDEW page at the time of your booking. By entering
          your payment information when requested, you authorize NEWDEW and its
          payment processors to charge and process the fees and charges assessed
          in connection with your reservations in accordance with the policy
          described on the Professional's NEWDEW page at the time of your
          booking. While NEWDEW takes what it believes to be reasonable efforts
          to ensure secure transmission of your information to third parties who
          assess and process payments, NEWDEW is not responsible for any fees or
          charges assessed by third parties or any errors in the processing of
          payments by third parties, including any errors that result from
          third-party negligence, improper transmission of payment information,
          your mistaken submission of payment information, or your submission of
          erroneous payment information.
        </p>
        <p>
          If you cancel or don't show up for an appointment, your credit card
          may still be charged in accordance with the cancellation policy of the
          Professional posted on such Professional's NEWDEW page at the time of
          your booking, at the Professional's discretion. NEWDEW facilitates the
          payment transaction per these Terms between you and each Professional,
          but is not responsible for mediating any resulting disputes. NEWDEW
          has no obligation to provide refunds or credits, but may grant them in
          extenuating circumstances, as a result of specific refund guarantee
          promotions, or to correct any errors made by NEWDEW, in each case in
          NEWDEW's sole discretion.
        </p>
        <p>
          Although the Services are intended to provide a Professional's
          availability in real-time, it is possible that a scheduling conflict
          may occur that requires the Professional to reschedule your
          reservation. Creation of a reservation using the Services is not a
          guarantee that the Professional will honor the reservation as
          scheduled.
        </p>
        <p>NEWDEW cannot guarantee availability of the Services to any User.</p>
        <p>
          NEWDEW does not independently confirm that Professionals are licensed
          to perform the Grooming Services offered by them on our Website.
          However, when Professionals create accounts with NEWDEW, Professionals
          certify to NEWDEW that they are a licensed professional, or if the
          Professional is a hair stylist, merchant or other business entity, all
          of such Professional's employees, independent contractors or agents
          who are providing Grooming Services each are a licensed professional,
          that they are legally able to provide the Grooming Services they offer
          to Clients on our Website, and that their business information is
          correctly represented on NEWDEW. NEWDEW reserves the right to remove
          or hide any incorrect, out of date, or illegal information from
          profiles, as well as remove or hide the entire profile itself at any
          time.
        </p>
        <p>
          <strong>Fees and Payment</strong>
        </p>
        <p>
          <strong>Fees</strong>
        </p>
        <p>
          Fees for the Subscription ("Subscription Fees") are set forth (a)
          herein, (b) online and/or (c) in a separately executed or accepted
          Subscription agreement between you and NEWDEW.
        </p>
        <p>
          You agree to pay NEWDEW the Subscription Fees, and any other
          applicable fees as may be agreed to by you. All fees are
          non-refundable and non-transferable except for as expressly provided
          in these Terms of Service. Unless otherwise stated, fees must be paid
          in advance of each billing period.
        </p>
        <p>
          Each Professional authorizes us to confirm that the credit card
          account or other payment method associated with their Account and used
          in connection with the Services is and remains in good standing with a
          financial institution as long as the Professional is using the
          Services. Each Professional authorizes us to obtain credit reports or
          other background inquiries from time to time to assess the
          Professional's eligibility to continue use of the Services. All
          balances and all fees, charges, and payments collected or paid through
          the Services are denominated in US dollars. Users of the Service will
          be liable for any taxes (including VAT, if applicable, required to be
          paid on the Services provided under the Agreement (other than taxes on
          the Company's income).
        </p>
        <p>
          <strong>Billing Cycle</strong>
        </p>
        <p>
          Your Subscription starts on the date that you sign up for a
          Subscription and submit payment. Unless otherwise communicated to you
          at the time of sign up, each Subscription cycle is one-month in length
          ("Subscription Period"), and will automatically renew on a monthly
          basis until we or you terminate the Subscription, or you notify us via
          the Services or by email to newdewnow@gmail.com of your decision to
          terminate your current Subscription. Unless otherwise communicated to
          you, you will be billed on the monthly anniversary of first submitting
          payment to us for your Subscription (e.g., if your Subscription begins
          on July 25th, it will renew on August 25th, September 25th, and so on.
          If your Subscription begins on a date that doesn't occur every billing
          cycle (e.g., the 31st), you will be billed on the last day of every
          month. We reserve the right to change the timing of our billing. You
          acknowledge that the amount billed to you each month may vary due to
          promotional offers and/or adding or changing your Subscription plan,
          and you authorize NEWDEW to charge you for these varying amounts as
          necessary.
        </p>
        <p>Subscription Pricing</p>
        <p>
          We reserve the right to adjust pricing for the Subscription Services
          at any time upon fourteen (14) days prior notice. Unless we expressly
          communicate otherwise, any price change to your membership will take
          effect on your next billing cycle. You must agree to the change in
          fees to continue to use the Services. To withdraw your consent, you
          will need to cancel your account by logging into your account and
          following the cancellation procedures there, or notifying us at
          newdewnow@gmail.com
        </p>
        <p>Trials</p>
        <p>
          NEWDEW may, from time to time, offer trial memberships which include
          free or discounted access to the Subscription during the designated
          trial period. Each trial membership will automatically convert to a
          regular monthly subscription if the subscription is activated by
          placing a credit card or other payment method on file. unless
          cancelled no more than forty-eight (48) hours prior to the end of your
          trial period. To cancel your subscription, logon to your account and
          follow the cancellation procedures indicated there, or email
          newdewnow@gmail.com
        </p>
        <p>Subscription Cancellation</p>
        <p>
          You may terminate your Subscription at any time. If you cancel with
          more than forty-eight (48) hours notice prior to your next billing
          cycle, you will not be charged for the next month of the Subscription
          Service. If you have any questions about your individual billing
          cycle, please log onto your account to view your billing cycle
          details, or contact us at newdewnow@gmail.com
        </p>
        <p>
          You will not be eligible for a prorated refund or any portion of the
          Subscription Fees paid for the then-current monthly subscription
          cycle. Following cancellation, you will continue to have access to the
          Subscription Services through the end of your current billing cycle.
        </p>
        <p>
          <strong>Accounts</strong>
        </p>
        <p>
          <strong>Account Registration</strong>
        </p>
        <p>
          By creating an account with NEWDEW (an "Account"), you are granted a
          right to use the Services provided by NEWDEW subject to the
          restrictions set forth in these Terms of Service and any other
          restrictions stipulated to you by us in writing. Our registration
          process will ask you for information including your name and other
          personal information, and in registering for an Account, you agree to
          provide true, accurate, current and complete information about
          yourself as prompted by the Services' registration process and as
          requested from time to time by NEWDEW (such information, "Registration
          Data"). You further agree that, in providing such Registration Data,
          you will not knowingly omit or misrepresent any material facts or
          information and that you will promptly enter corrected or updated
          Registration Data via the Services, or otherwise advise us promptly in
          writing of any changes or updates to your Registration Data. You
          further consent and authorize us to verify your Registration Data as
          required for your use of and access to the Services. We reserve the
          right to suspend or terminate the Account of any User who provides
          inaccurate, untrue, or incomplete information, or who fails to comply
          with the account registration requirements.
        </p>
        <p>Account ID</p>
        <p>
          Once you register for the Services, you shall receive a unique user
          identification and password in connection with your Account
          (collectively referred to herein as "Account IDs"). You must use your
          true and accurate name when signing up for an Account. You are
          required to select an appropriate and non-offensive Account ID.
          Ideally, the Account ID you choose will be a reasonably descriptive
          name that clearly identifies you. We reserve the right to remove or
          reclaim any Account ID if we believe, in our sole discretion, the
          Account ID is inappropriate, offensive or confusing or potentially
          violates the rights of a third party (such as when a trademark owner
          provides notice that an Account ID is similar to the trademark and the
          Account ID does not closely relate to the User's actual name or
          trademark rights).
        </p>
        <p>Your Account</p>
        <p>
          You agree to maintain your Account solely for your own use. You agree
          that you will not allow another person to use your Account IDs to
          access or use the Services under any circumstances. You are solely and
          entirely responsible for maintaining the confidentiality of your
          Account IDs and for any charges, damages, liabilities or losses
          incurred or suffered as a result of you failure to do so. We are not
          liable for any harm caused by or related to the theft of your Account
          IDs, your disclosure of your Account IDs, or your authorization to
          allow another person to access or use the Services using your Account
          IDs. Furthermore, you are solely and entirely responsible for any and
          all activities that occur under your Account including any charges
          incurred relating to the Services. You agree to immediately notify us
          of any unauthorized use of your Account or any other breach of
          security known to you. You acknowledge that the complete privacy of
          your data and messages transmitted while using the Services cannot be
          guaranteed.
        </p>
        <p>Linking Your Account</p>
        <p>
          As part of the functionality of the Services, you may link your
          Account with online accounts you may have with third party service
          providers (each such account, a "Third Party Account") by either: (i)
          providing your Third Party Account login information through the
          Services; or (ii) allowing NEWDEW to access your Third Party Account,
          as is permitted under the applicable terms and conditions that govern
          your use of each Third Party Account. You represent that you are
          entitled to disclose your Third Party Account login information to
          NEWDEW and/or grant NEWDEW access to your Third Party Account
          (including, but not limited to, for use for the purposes described
          herein), without breach by you of any of the terms and conditions that
          govern your use of the applicable Third Party Account and without
          obligating NEWDEW to pay any fees or making NEWDEW subject to any
          usage limitations imposed by such third party service providers. By
          granting NEWDEW access to any Third Party Accounts, you understand
          that (i) NEWDEW may access, make available and store (if applicable)
          any content that you have provided to and stored in your Third Party
          Account (the "SNS Content") so that it is available on and through the
          Services via your Account, including without limitation any friend
          lists, and (ii) NEWDEW may submit and receive additional information
          to your Third Party Account to the extent you are notified when you
          link your Account with the Third Party Account. Unless otherwise
          specified in these Terms of Service, all SNS Content, if any, shall be
          considered to be Content (as defined below) for all purposes of these
          Terms of Service. Depending on the Third Party Accounts you choose and
          subject to the privacy settings that you have set in such Third Party
          Accounts, personally identifiable information that you post to your
          Third Party Accounts may be available on and through your Account on
          the Services. Please note that if a Third Party Account or associated
          service becomes unavailable or NEWDEW's access to such Third Party
          Account is terminated by the third party service provider, then SNS
          Content may no longer be available on and through the Services. You
          will have the ability to disable the connection between your Account
          and your Third Party Accounts at any time. PLEASE NOTE THAT YOUR
          RELATIONSHIP WITH THE THIRD PARTY SERVICE PROVIDERS ASSOCIATED WITH
          YOUR THIRD PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH
          SUCH THIRD PARTY SERVICE PROVIDERS. NEWDEW makes no effort to review
          any SNS Content for any purpose, including but not limited to, for
          accuracy, legality or non-infringement, and NEWDEW is not responsible
          for any SNS Content.
        </p>
        <p>
          The Services may contain links to third party websites that are not
          owned or controlled by NEWDEW. NEWDEW has no control over, and assumes
          no responsibility for, the content, privacy policies, or practices of
          any third party websites. In addition, NEWDEW will not and cannot
          censor or edit the content of any third-party site. By using the
          Services, you expressly relieve NEWDEW from any and all liability
          arising from your use of any third-party website.
        </p>
        <p>
          You acknowledge and agree that the Services may access your e-mail
          address book associated with a Third Party Account and your contacts
          list stored on your mobile device or tablet computer solely for the
          purposes of identifying and informing you of those contacts who have
          also registered to use the Services. At your request made via e-mail
          to newdewnow@gmail.com, NEWDEW will deactivate the connection between
          the NEWDEW Services and your Third Party Account and any information
          stored on NEWDEW's servers that was obtained through such Third Party
          Account will be hidden, except the username and profile picture
          associated with your Account.
        </p>
        <p>
          <strong>Termination of Accounts</strong>
        </p>
        <p>
          NEWDEW reserves the right, in its sole discretion, to terminate your
          Account if you violate these Terms of Service or for any reason or no
          reason at any time. We may also suspend your access to the Services
          and your Account (including the funds in your Account) if you (a) have
          violated the terms of these Terms of Service, any other agreement you
          have with NEWDEW, including without limitation the EULA, or NEWDEW's
          policies, (b) pose an unacceptable credit or fraud risk to us, (c)
          provide any false, incomplete, inaccurate, or misleading information
          or otherwise engage in fraudulent or illegal conduct, or (d) for any
          other reason in NEWDEW's sole discretion.
        </p>
        <p>
          If your Account is terminated or suspended for any reason or no
          reason, you agree: (a) to continue to be bound by these Terms of
          Service, (b) to immediately stop using the Services, (c) that any
          licenses granted to you under these Terms of Service shall end, (d)
          that we reserve the right (but have no obligation) to hide or delete
          all of your information and account data stored on our servers, and
          (e) that NEWDEW shall not be liable to you or any third party for
          termination or suspension of access to the Services or for deletion or
          hiding of your information or account data. You agree that NEWDEW may
          retain and use your information and account data as needed to comply
          with investigations and applicable law.
        </p>
        <p>
          We will not be liable to you for compensation, reimbursement, or
          damages in connection with your use of the Services, or in connection
          with any termination or suspension of the Services. Any termination of
          these Terms of Service does not relieve you of any obligations to pay
          any Fees or costs accrued prior to the termination and any other
          amounts owed by you to us as provided in these Terms of Service.
        </p>
        <p>
          NEWDEW is not liable for any losses relating to chargebacks,
          fraudulent charges, or other actions by any User that are deceptive,
          fraudulent or otherwise invalid as determined in NEWDEW's sole
          discretion ("Fraudulent Actions"). By using the Services, you hereby
          release NEWDEW from any liability arising from Fraudulent Actions. You
          will also use best efforts to promptly notify NEWDEW of any Fraudulent
          Actions which may affect the Services. NEWDEW reserves the right, in
          its sole discretion, to terminate the account of any User that engages
          in, or enables any other User to engage in, Fraudulent Actions.
        </p>
        <p>
          <strong>Payment Processing Services</strong>
        </p>
        <p>
          <strong>NEWDEW's Role</strong>
        </p>
        <p>
          The Services allow Clients to pay and Professionals to accept payments
          for Grooming Services, including Card-based payments initiated with
          Cards bearing the trademarks of MasterCard International Inc. and Visa
          Inc. (collectively, the "Networks") through a payment service provider
          retained by the Company (the "PSP"). We are not a bank and we do not
          offer banking services as defined by the United States Department of
          Treasury.
        </p>
        <p>
          NEWDEW facilitates the processing of payments Professionals receive
          from Clients. This means that we collect, analyze and relay
          information generated in connection with these payments.
        </p>
        <p>
          <strong>Authorization</strong>
        </p>
        <p>
          You authorize us to process payments in accordance with the Services,
          using the payment information you have supplied. Users of the Service
          will be required to provide their credit card or bank account details
          to Company and the PSP.
        </p>
        <p>
          Within 24 hours after you receive confirmation through the Service or
          via email that a Grooming Service has been completed, you agree to
          authorize the Company to provide your payment details to the PSP for
          processing of payments, out of pocket expenses owed to a Professional,
          any tip or gratuity, if applicable, and the service fees and trust and
          safety fees owed to Company for the use of the Service. You may be
          charged a cancellation fee through the PSP if you book a Grooming
          Service, but cancel it before it is completed.
        </p>
        <p>
          <strong>Third Party Payment Service Provider</strong>
        </p>
        <p>
          Users will be required to provide their credit card or bank account
          details to the Company and the PSP. As a condition of NEWDEW enabling
          payment processing services through the PSP, you agree to provide
          NEWDEW accurate and complete information about you and/or your
          business, and you authorize NEWDEW to share it and transaction
          information related to your use of the PSP services.
        </p>
        <p>
          Notwithstanding any amounts owed to Company hereunder, COMPANY DOES
          NOT PROCESS PAYMENT FOR ANY SERVICES. To facilitate payment for the
          Service via bank account, credit card, or debit card, we use Stripe,
          Inc. and its affiliates (“Stripe”), (in addition to PayPal and Square,
          discussed below) a third-party payment processor (collectively,
          “Payment Processors”). The payment processing services are provided by
          Stripe and are subject to the Stripe terms and conditions and other
          policies available at https://stripe.com/legal and Stripe’s Global
          Privacy Policy available at: https://stripe.com/privacy (collectively,
          the "Stripe Agreements"), and the other applicable Payment Processor’s
          terms and conditions, privacy policy, and all other relevant
          agreements (described below) (collectively, the “Payment Processor
          Agreements”). By agreeing to these Terms of Service, users that use
          the payment functions of the Service also agree to be bound by the
          Stripe Agreements, and the other applicable Payment Processor
          Agreements, for the payment function the user is using, as the same
          may be modified by Stripe, and the other applicable Payment
          Processors, from time to time. You hereby authorize Stripe, and the
          other applicable Payment Processors, to store and continue billing
          your specified payment method even after such payment method has
          expired, to avoid interruptions in payment for your use of the
          Service. Please contact Stripe, or the other applicable Payment
          Processor, for more information. Company assumes no liability or
          responsibility for any payments you make through the Service.
        </p>
        <p>
          NEWDEW also may use Square, Inc. (Square) for payment processing
          services. By using the Square payment processing services you agree to
          Square's Terms of Service available at
          https://squareup.com/us/en/legal/general/ua.
        </p>
        <p>
          NEWDEW also may use Braintree, a division of PayPal, Inc. (Braintree)
          for payment processing services. By using the Braintree payment
          processing services you agree to the Braintree Payment Services
          Agreement available at
          https://www.braintreepayments.com/legal/gateway-agreement, and the
          applicable bank agreement available at
          https://www.braintreepayments.com/legal/cea-wells.
        </p>
        <p>
          Company reserves the right, in its sole discretion (but not the
          obligation), to (i) place on hold any payment and out of pocket
          expenses, or (ii) refund, provide credits or arrange for the PSP to do
          so.
        </p>
        <p>
          <strong>Unauthorized or Illegal Use</strong>
        </p>
        <p>
          We may decide not to authorize or settle any transaction that you
          submit to us if we believe in our sole discretion that the transaction
          is in violation of these Terms of Service or any other NEWDEW
          agreement, or that it exposes Professionals, other NEWDEW Users, the
          PSP or NEWDEW to harm. Harm includes fraud and other criminal acts as
          determined by NEWDEW in our sole discretion. If we reasonably suspect
          that your Account has been used for an unauthorized, illegal, or
          criminal purpose, you give us express authorization to share
          information about you, your Account, and any of your transactions with
          law enforcement.
        </p>
        <p>
          <strong>Accepted Cards</strong>
        </p>
        <p>
          NEWDEW works with any US-issued and most non-US issued credit, debit,
          prepaid, or gift cards ("Cards") with a Visa, MasterCard, or Discover
          logo. We may remove or add Cards that we accept at any time without
          prior notice. We will generally only process Cards that receive an
          authorization from the applicable Network and Card issuer.
        </p>
        <p>
          <strong>NEWDEW Fees</strong>
        </p>
        <p>
          Subject to the terms of these Terms of Service, we reserve the right
          to change our Fees immediately upon notice. You must agree to the
          change in Fees to continue to use the Services. To withdraw your
          consent, you will need to close your Account. All balances and all
          Fees, charges, and payments collected or paid through the Services are
          denominated in US dollars.
        </p>
        <p>
          <strong>Customer Care</strong>
        </p>
        <p>
          Professionals, as a separate entity from NEWDEW, are solely
          responsible for all customer service issues relating to such
          Professional's goods or services, including without limitation, any
          Grooming Services, pricing, order fulfillment, order or appointment
          cancellation, returns, refunds and adjustments, rebates, functionality
          and warranty, and feedback concerning experiences with such
          Professional, any personnel, their policies or processes. As between
          Clients and NEWDEW, NEWDEW is solely responsible for customer service
          issues relating to any Account, payment, Card processing, debiting or
          crediting.
        </p>
        <p>
          <strong>Professional's Loyalty Programs</strong>
        </p>
        <p>
          Professionals may offer loyalty programs to Clients. If a Professional
          offers such a loyalty program, the Professional (and not NEWDEW) is
          responsible for ensuring that its program and any associated rewards
          are compliant with applicable federal and state laws, including laws
          governing prepaid cards and special offers such as rebates and
          coupons. The Professionals agree to make available to Clients any
          terms and conditions applicable to such Professional's loyalty
          program.
        </p>
        <p>
          <strong>Payment Processing Services</strong>
        </p>
        <p>
          <strong>NEWDEW's Role</strong>
        </p>
        <p>
          The Services allow Clients to pay and Professionals to accept payments
          for Grooming Services, including Card-based payments initiated with
          Cards bearing the trademarks of MasterCard International Inc. and Visa
          Inc. (collectively, the "Networks") through a payment service provider
          retained by the Company (the "PSP"). We are not a bank and we do not
          offer banking services as defined by the United States Department of
          Treasury.
        </p>
        <p>
          NEWDEW facilitates the processing of payments Professionals receive
          from Clients. This means that we collect, analyze and relay
          information generated in connection with these payments.
        </p>
        <p>
          <strong>Authorization</strong>
        </p>
        <p>
          You authorize us to process payments in accordance with the Services,
          using the payment information you have supplied. Users of the Service
          will be required to provide their credit card or bank account details
          to Company and the PSP.
        </p>
        <p>
          Within 24 hours after you receive confirmation through the Service or
          via email that a Grooming Service has been completed, you agree to
          authorize the Company to provide your payment details to the PSP for
          processing of payments, out of pocket expenses owed to a Professional,
          any tip or gratuity, if applicable, and the service fees and trust and
          safety fees owed to Company for the use of the Service. You may be
          charged a cancellation fee through the PSP if you book a Grooming
          Service, but cancel it before it is completed.
        </p>
        <p>
          <strong>Third Party Payment Service Provider</strong>
        </p>
        <p>
          Users will be required to provide their credit card or bank account
          details to the Company and the PSP. As a condition of NEWDEW enabling
          payment processing services through the PSP, you agree to provide
          NEWDEW accurate and complete information about you and/or your
          business, and you authorize NEWDEW to share it and transaction
          information related to your use of the PSP services.
        </p>
        <p>
          Notwithstanding any amounts owed to Company hereunder, COMPANY DOES
          NOT PROCESS PAYMENT FOR ANY SERVICES. To facilitate payment for the
          Service via bank account, credit card, or debit card, we use Stripe,
          Inc. and its affiliates (“Stripe”), (in addition to PayPal and Square,
          discussed below) a third-party payment processor (collectively,
          “Payment Processors”). The payment processing services are provided by
          Stripe and are subject to the Stripe terms and conditions and other
          policies available at https://stripe.com/legal and Stripe’s Global
          Privacy Policy available at: https://stripe.com/privacy (collectively,
          the "Stripe Agreements"), and the other applicable Payment Processor’s
          terms and conditions, privacy policy, and all other relevant
          agreements (described below) (collectively, the “Payment Processor
          Agreements”). By agreeing to these Terms of Service, users that use
          the payment functions of the Service also agree to be bound by the
          Stripe Agreements, and the other applicable Payment Processor
          Agreements, for the payment function the user is using, as the same
          may be modified by Stripe, and the other applicable Payment
          Processors, from time to time. You hereby authorize Stripe, and the
          other applicable Payment Processors, to store and continue billing
          your specified payment method even after such payment method has
          expired, to avoid interruptions in payment for your use of the
          Service. Please contact Stripe, or the other applicable Payment
          Processor, for more information. Company assumes no liability or
          responsibility for any payments you make through the Service.
        </p>
        <p>
          NEWDEW also may use Square, Inc. (Square) for payment processing
          services. By using the Square payment processing services you agree to
          Square's Terms of Service available at
          https://squareup.com/us/en/legal/general/ua.
        </p>
        <p>
          NEWDEW also may use Braintree, a division of PayPal, Inc. (Braintree)
          for payment processing services. By using the Braintree payment
          processing services you agree to the Braintree Payment Services
          Agreement available at
          https://www.braintreepayments.com/legal/gateway-agreement, and the
          applicable bank agreement available at
          https://www.braintreepayments.com/legal/cea-wells.
        </p>
        <p>
          Company reserves the right, in its sole discretion (but not the
          obligation), to (i) place on hold any payment and out of pocket
          expenses, or (ii) refund, provide credits or arrange for the PSP to do
          so.
        </p>
        <p>
          <strong>Unauthorized or Illegal Use</strong>
        </p>
        <p>
          We may decide not to authorize or settle any transaction that you
          submit to us if we believe in our sole discretion that the transaction
          is in violation of these Terms of Service or any other NEWDEW
          agreement, or that it exposes Professionals, other NEWDEW Users, the
          PSP or NEWDEW to harm. Harm includes fraud and other criminal acts as
          determined by NEWDEW in our sole discretion. If we reasonably suspect
          that your Account has been used for an unauthorized, illegal, or
          criminal purpose, you give us express authorization to share
          information about you, your Account, and any of your transactions with
          law enforcement.
        </p>
        <p>
          <strong>Accepted Cards</strong>
        </p>
        <p>
          NEWDEW works with any US-issued and most non-US issued credit, debit,
          prepaid, or gift cards ("Cards") with a Visa, MasterCard, or Discover
          logo. We may remove or add Cards that we accept at any time without
          prior notice. We will generally only process Cards that receive an
          authorization from the applicable Network and Card issuer.
        </p>
        <p>
          <strong>NEWDEW Fees</strong>
        </p>
        <p>
          If applicable, you agree to pay the applicable fees listed on our Fee
          Schedule for use of the Services ("Fees"). Subject to the terms of
          these Terms of Service, we reserve the right to change our Fees
          immediately upon notice. You must agree to the change in Fees to
          continue to use the Services. To withdraw your consent, you will need
          to close your Account. All balances and all Fees, charges, and
          payments collected or paid through the Services are denominated in US
          dollars.
        </p>
        <p>NEWDEW Programs</p>
        <p>
          NEWDEW may at any time in its sole discretion, change, modify, add to,
          supplement or delete the terms and conditions of any NEWDEW Program,
          including without limitation changing the name, fees and eligibility
          requirements to participate in such NEWDEW Program. The Company will
          endeavor to notify you of any material changes by email, but will not
          be liable for any failure to do so. In any case, the liability of any
          of Company's partners pursuant to such NEWDEW Programs shall be
          limited as described in these Terms of Service, including but not
          limited to Section 12 of these Terms of Service.
        </p>
        <p>
          The NEWDEW Programs may, among other things, offer certain benefits to
          Clients for referring Professionals or Clients to the Services. The
          NEWDEW Programs may offer certain premium services for additional fees
          to certain Users. Currently, each User will have the choice to join
          that NEWDEW Program, however, in the future, the participation in
          certain of the NEWDEW Programs may become mandatory for certain Users.
        </p>
        <p>
          If any future changes to these Terms of Service are unacceptable to
          you or cause you to no longer be in compliance with these Terms of
          Service, you must terminate, and immediately stop using, the Services.
        </p>
        <p>Gift Cards</p>
        <p>
          At times and under circumstances in Company's sole discretion, the
          Company may issue to certain Users gift cards ("Gift Cards") that can
          be used to pay for Services, including the Grooming Services, and fees
          or expenses associated with the Services. Gift Cards are not
          replaceable if lost or stolen and have no expiration date, but in
          certain states, after a period of time, we may be required to remit
          the cash associated with unused Gift Card balances to certain states
          pursuant to their abandoned property laws. Once we have remitted such
          cash to a state, if someone tries to redeem the Gift Card, it may no
          longer be redeemed and we may direct them to that state's government
          instead. Gift Cards are not redeemable by the Company for cash except
          as required by applicable law.
        </p>
        <p>
          If you wish to redeem your Gift Card and you can do so under
          applicable law, simply send your Gift Card along with a self-addressed
          envelope to Attn: Gift Cards, 4491 Cheshire Station Plaza, PMB #165,
          Dale City, VA 22193.
        </p>
        <p>
          We'll verify your address, confirm that your Gift Card balance
          qualifies you for redemption, and send you a check for the exact
          amount in the envelope provided. If we determine that the Gift Card is
          not required to be redeemed under applicable law, or we can't verify
          your residency, we'll return your Gift Card in the envelope provided.
          Company will be unable to redeem or return your Gift Card without a
          self-addressed envelope and is not responsible for mail that doesn't
          arrive at the address provided and cannot guarantee delivery in any
          way after submittal to the US Postal Service.
        </p>
        <p>
          A Gift Card cannot be combined with any other Gift Cards, gift
          certificates or other coupons for any given individual transaction on
          the Website. A Gift Card cannot be used as a credit or debit card.
          Company reserves the right to limit quantities of Gift Cards purchased
          by any person or entity and to cancel a Gift Card if it believes that
          the Gift Card was obtained through fraudulent or unauthorized means.
        </p>
        <p>
          No credit card, credit line, overdraft protection, or deposit account
          is associated with your Gift Card. You cannot "reload" (i.e., you
          cannot add value/balance to your Gift Card at this time). If a Gift
          Card holder's purchase exceeds the amount of that Gift Card's balance,
          the Gift Card holder must pay the difference by another means. Unused
          Gift Card balances are not transferable. Company reserves the right to
          correct the balance of a Gift Card if Company believes that a billing
          error has occurred. Company disclaims all liability for any such
          billing errors. Gift Cards and their use are subject to these Terms of
          Service, and use of a Gift Card constitutes acceptance hereof. Unused
          Gift Card balances are not transferable.
        </p>
        <p>Text Messaging Terms and Conditions</p>‍
        <p>
          By opting in to NEWDEW INC., or our subsidiaries or affiliates
          (hereinafter, “We,” “Us,” “Our”) (the “Service”), you agree to these
          Text Messaging Terms and Conditions (the “Agreement”). Your
          participation in the Service is also subject to our Privacy Policy,
          Terms of Use and any other applicable terms and agreements related to
          your use of Our services. This Agreement is not intended to modify
          other terms or the Privacy Policy that may govern the relationship
          between you and Us in other contexts.
        </p>
        <p>
          You acknowledge that by voluntarily providing your telephone
          number(s), you expressly agree to receive ongoing recurring automated
          text messages (such as SMS, MMS, or successor protocols or
          technologies) from, or on behalf of, Us that may be sent using an
          automatic telephone dialing system concerning the marketing and sale
          of Our products, services, offers, promotions, and events, as well as
          your relationship with Us, including your orders and the products and
          services that you have inquired about. You understand that consent is
          not required to make any purchase from Us. Message and data rates may
          apply. Message frequency may vary.
        </p>
        <p>
          Your consent to receive automated texts is completely voluntary. You
          may opt-out at any time. To opt out of text messages, reply “STOP”,
          “END”, “CANCEL”, “UNSUBSCRIBE”, or “QUIT” to any mobile message from
          Us or email
          <a href="mailto:newdewnow@gmail.com">newdewnow@gmail.com</a> and
          specify that you want to opt out of text messages. You may also text
          “HELP” or email Us at
          <a href="mailto:newdewnow@gmail.com">newdewnow@gmail.com</a> for help.
          You acknowledge and agree to accept a final text message confirming
          your opt-out. We may use different shortcodes for different messaging
          purposes, so texting STOP”, “END”, “CANCEL”, “UNSUBSCRIBE”, or “QUIT”
          to one shortcode will not effectuate a stop request for all shortcodes
          to which you are subscribed. If you would like to stop messages from
          multiple shortcodes, text STOP”, “END”, “CANCEL”, “UNSUBSCRIBE”, or
          “QUIT” to each shortcode to which you are subscribed. Text messages
          may not be available on all mobile carriers. For help, text “HELP” to
          any text message from Us, or email{' '}
          <a href="mailto:newdewnow@gmail.com">newdewnow@gmail.com</a>. Text
          “STOP” to cancel.
        </p>
        <p>
          We will not be liable for any delays in the receipt of any SMS
          messages connected with this Program. Delivery of SMS messages is
          subject to effective transmission from your wireless service
          provider/network operator, and is outside of Our control. T-Mobile is
          not liable for delayed or undelivered messages.
        </p>
        <p>
          We will send you information regarding your account, such as
          confirmations, invoices, technical notices, updates, security alerts,
          and support and administrative messages (“Service Messages”) by SMS.
          You may not opt out of receiving Service Messages from Us unless you
          deactivate your account.
        </p>
      </div>
    </Fragment>
  );
};

export default TermsOfService;
