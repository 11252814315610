import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import './Modal.scss';
import { Button, Typography, } from '@mui/material';
import coilsAndCurlsImage from '../../assets/lady-back.png';
import { CategoriesContext } from '../../contexts/categories.context';
import ProductCard from '../../components/product-card/product-card.component';
import Twitter from '@mui/icons-material/Twitter';
import Instagram from '@mui/icons-material/Instagram';
import LinkedIn from '@mui/icons-material/LinkedIn';
import GitHub from '@mui/icons-material/GitHub';
import EditNoteSharp from '@mui/icons-material/EditNoteSharp'
import Delete from "@mui/icons-material/Delete"
import { Link } from 'react-router-dom';
import CopyUrlButton from './CopyUrl';
import postpic from '../../assets/kids-hair.avif'
import ShareCopyDropdown from './CopyUrl';
import ReactQuill from 'react-quill';
import { parse } from 'node-html-parser';
import { UserContext } from '../../contexts/user.context';
import { useLocation } from 'react-router-dom';
import { DeletePost, UpdatePost } from "../../utils/firebase/firebase.utils"


const Modal = ({ isOpen, onClose, product, postCategory }) => {


    const { currentUser } = useContext(UserContext);
    const { categoriesMap } = useContext(CategoriesContext);
    const { title, imageUrl, content, username, datePosted, id, writerId } = product;


    const [products, setProducts] = useState(categoriesMap['misc']?.slice(2));
    const [edit, setEdit] = useState(false)
    const [editedContent, setEditedContent] = useState(content)
    const quillRef = useRef(null);
    const location = useLocation();
    const currentUrl = location.pathname;


    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ header: '1' }, { header: '2' }, { font: [] }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ align: [] }],
                [{ color: [] }, { background: [] }],
                ['clean'],
            ],
        },
        clipboard: {
            matchVisual: false,
        },
    }), []);

    if (!isOpen) return null;
    const postCategories = Object.keys(categoriesMap).map((title) => title);
    const formatFirestoreTimestamp = (timestamp) => {
        // Convert Firestore Timestamp to JavaScript Date
        const date = new Date(timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000);

        // Array of month names
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];

        // Get month and day
        const month = monthNames[date.getMonth()];
        const day = date.getDate();

        // Format as "Month Day"
        return `${month} ${day}`;
    };
    const theArticle = parse(content)
    const formattedDate = formatFirestoreTimestamp(datePosted);



    const handleDelete = async () => {
        const confirmed = window.confirm('Are you sure you want to delete this post?');
        if (confirmed) {
            await DeletePost(postCategory, id);
        }
    };


    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'video',
        'color',
        'background',
        'align',
    ];
    const handleContentChange = (value) => {
        setEditedContent(value);
    };


    const handleSubmitEdit = async () => {
        await UpdatePost(postCategory, id, editedContent)

        if (quillRef.current) {
            quillRef.current.getEditor().setText('');
        }
        setEditedContent('')
    }

    const handleCancelEdit = () => {
        setEdit(false)
        setEditedContent(null)
    }

    return (
        <div className="modal-overlay">
            <button className="close-button" onClick={onClose}>X</button>

            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <nav className="nav-container">
                    <div className="flex-item">
                        <ul className="nav-list">
                            <p className='nav-link nav-link-all'>All Posts</p>
                            {postCategories.map((category, index) => (
                                <li key={index} className="nav-item">
                                    <a href={`/posts/${category.trim()}`} className="nav-link">{category}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {
                        (currentUser && (currentUser?.uid == writerId) || (currentUser?.uid == writerId)) &&
                        <div className="flex-item editdelete">
                            <EditNoteSharp className="modify-post edit-post" onClick={() => setEdit(!edit)} />
                            <Delete className="modify-post delete-post" onClick={() => handleDelete()} />
                        </div>
                    }
                </nav>

                <article className='outter-modal-div'>
                    <div className='link-container'>
                        <div className=" about-article  left-link">
                            <img src={postpic} className='write-avatar' />
                            <div className='article-details'>
                                <p>{username || "UserName"}</p>
                                <p>{formattedDate || 'May 24'}</p>
                                <p>3 min read</p>
                            </div>
                        </div>


                        <div className="right-link">
                            <ShareCopyDropdown />
                        </div>
                    </div>
                    <div className="modal-inner">
                        <h1>{title}</h1>
                        <img src={imageUrl} alt={title} className="modal-image" />

                        <Typography
                            dangerouslySetInnerHTML={{ __html: theArticle }}
                            component="p"
                        />
                        {edit &&
                            <div>
                                <ReactQuill
                                    value={editedContent}
                                    onChange={handleContentChange}
                                    modules={modules}
                                    formats={formats}
                                    className="modal-textarea"
                                    theme="snow"
                                    ref={quillRef}
                                />
                                <div style={{ margin: '7rem 0' }}>
                                    <Button
                                        onClick={async () => handleSubmitEdit()

                                        }
                                    >Submit Edit</Button>
                                    <Button
                                        onClick={() => handleCancelEdit()}
                                    >Cancel Edit</Button>
                                </div>

                            </div>
                        }
                        <Typography gutterBottom component="span">
                            —{username}
                        </Typography>
                        <hr className='line' />
                        <div className='socials-div'>
                            <div className='socials'>
                                <Link to="/">
                                    <Instagram className="social-media instagram" />
                                </Link>
                                <Link to="#">
                                    <Twitter className="social-media twitter" />
                                </Link>
                                <a
                                    href="http://www.linkedin.com/company/newdew"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <LinkedIn className="social-media linkedin" />
                                </a>
                            </div>
                            <div className='current-category'>


                                <Link to={`${'/posts/' + postCategory?.replace(/\s+/g, '')}`}>
                                    {postCategory?.toUpperCase()}
                                </Link>
                            </div>
                        </div>

                    </div>
                </article>
                <div className="link-container">
                    <p className="left-link" >
                        Recent Posts
                    </p>
                    <Link to="/posts" className="right-link" onClick={() => {
                        if (currentUrl == '/posts') {
                            onClose()
                        }
                    }}>
                        See All
                    </Link>
                </div>

                <div className="category-container">
                    {products &&
                        products.map((product) => (
                            <ProductCard key={product.id} product={product} />
                        ))}
                </div>
            </div>
        </div>
    );
};

export default Modal;
