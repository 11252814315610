import { v4 as uuidv4 } from 'uuid';
import React, { useState } from 'react';
import { mainlingList } from '../.././utils/firebase/firebase.utils';
import Alert from './alert.tsx';
import './cube.css';
import './FormStyles.scss';
import { FaAndroid, FaApple } from 'react-icons/fa'; // Importing icons
import { object, string, number, date, InferType } from 'yup';
// Importing images
import imagesGif from '../../assets/gif/animated-gif.gif';
import newdewgirl from '../../assets/newdewgirl.png';
import newdewgirltwo from '../../assets/newdewgirltwo.png';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

type FormData = {
  email: string;
};

const initialState = {
  email: '',
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

let userSchema = object({
  email: string().email(),
});

const JoinEmailList = () => {
  const [{ email }, setFormData] = useState<FormData>(initialState);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [showGif, setShowGif] = useState(false);
  const [phoneType, setPhoneType] = useState({
    android: false,
    ios: false,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleError = (message: string) => {
    setError(message);
  };
  const handlePhoneTypeChange = (e) => {
    const { name, checked } = e.target;
    setPhoneType((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Check if at least one phone type is selected
    if (!phoneType.android && !phoneType.ios) {
      handleError('Please select your phone type.');
      return;
    }

    try {
      const user = await userSchema.validate({ email });
      const auth = uuidv4();
      const res = await mainlingList(auth, { email });

      if (res === 'SUCCESS') {
        setSuccess(true);
        setFormData(initialState);
      } else {
        handleError('This email is already in use!');
      }
    } catch (error) {
      handleError('Validation failed: ' + error.message);
    }
  };

  const handleFocus = () => {
    setError('');
  };

  const handleButtonClick = () => {
    setShowGif(!showGif);
  };
  return (
    <div style={{}}>
      <div></div>
      <div className="center-content">
        <h2 style={{ margin: '0 auto', textAlign: 'center', fontSize: 36 }}>
          <span
            style={{ fontFamily: 'beezle', fontSize: 84, color: '#370101' }}
          >
            {' '}
            NEW DEW
          </span>
          <span style={{ position: 'relative', right: 25 }}>
            Is the Wind in Your Hair
          </span>
        </h2>
        <p
          className="sub-text"
          style={{
            margin: '30px auto',
            textAlign: 'center',
            fontSize: 20,
            color: 'lightseagreen',
            fontWeight: 'bold',
          }}
        >
          Be the First to Try NEW DEW!
        </p>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap',
            padding: '10px',
          }}
        >
          <img
            src={newdewgirltwo}
            style={{
              height: 'auto',
              width: '40%',
              maxWidth: '400px',
              borderRadius: '20px',
              margin: '10px',
            }}
          />
          <img
            src={newdewgirl}
            style={{
              height: 'auto',
              width: '40%',
              maxWidth: '400px',
              borderRadius: '20px',
              margin: '10px',
              transform: 'rotate(30deg)',
            }}
          />
        </div>
        <div
          className="center-content"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 60,
            textAlign: 'center',
          }}
        >
          <span style={{ fontSize: 24 }}>
            We make booking hair appointments a breeze,
          </span>
          <br />
          <span style={{ fontSize: 24 }}>
            with professional stylists, open pricing, bids, and a lot more!
          </span>
          <br />
          <span style={{ fontSize: 24 }}>
            Join us today for easier access to hair care.
          </span>
        </div>
      </div>
      <section>
        <div className="list-section-div">
          <div className="container">
            <div className="form-container pt-4 pb-8 lg:py-8 px-4 mx-auto max-w-screen-md form">
              <form
                onSubmit={(e) => handleSubmit(e)}
                className="form-container"
                style={{ alignItems: 'center' }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '20px',
                  }}
                >
                  <label
                    className="label"
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      color: '#800317',
                    }}
                  >
                    Phone type
                  </label>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '10px',
                    }}
                  >
                    <label
                      style={{
                        marginRight: '20px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <input
                        type="checkbox"
                        name="android"
                        checked={phoneType.android}
                        onChange={handlePhoneTypeChange}
                        style={{ marginRight: '8px' }}
                      />
                      <FaAndroid style={{ marginRight: '5px' }} />
                      Android
                    </label>
                    <label style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type="checkbox"
                        name="ios"
                        checked={phoneType.ios}
                        onChange={handlePhoneTypeChange}
                        style={{ marginRight: '8px' }}
                      />
                      <FaApple style={{ marginRight: '5px' }} />
                      iOS
                    </label>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    position: 'relative',
                    right: 40,
                    top: 15,
                  }}
                >
                  <label htmlFor="email" className="label blinking-text">
                    Email
                  </label>
                  <div className="form-divs">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      className="list-input"
                      placeholder="email"
                      onFocus={handleFocus}
                      required
                      disabled={success}
                    />
                  </div>
                </div>

                <p
                  className="error"
                  style={{ bottom: 30, position: 'relative' }}
                >
                  {error}
                </p>
                <div className="submit-container">
                  <input type="submit" value="Join" className="submit" />
                </div>
              </form>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <button
              onClick={handleButtonClick}
              style={{
                padding: '10px 40px',
                fontSize: '16px',
                cursor: 'pointer',
                backgroundColor: '#1f0f19',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                marginBottom: '20px',
              }}
            >
              {showGif ? 'Hide Screenshots' : 'Show Screenshots'}
            </button>

            {showGif && (
              <img
                src={imagesGif}
                alt="App Screenshots"
                style={{ height: 'auto', width: '80%', maxWidth: '600px' }}
              />
            )}
          </div>

          {success && (
            <div className="alert-overlay">
              <div className="alert-content">
                <Alert
                  subject="You're In! Please Verify Your Email 💌"
                  message={` Hi ${email},
        Congratulations! 🎉 You're now part of our exclusive mailing list.
        To complete your subscription, please check your email inbox for a verification link.
        Clicking this link ensures you receive our exciting updates, special offers, and more directly in your inbox.
        We can't wait to share our latest news with you, 📩 Welcome aboard!`}
                  onClose={() => {
                    setSuccess(!success);
                    setPhoneType({
                      android: false,
                      ios: false,
                    });
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};
export default JoinEmailList;
