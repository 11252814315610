import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import SectionNavigation from './section-navigation/section-navigation.component';
import Twitter from '@mui/icons-material/Twitter';
import Instagram from '@mui/icons-material/Instagram';
import LinkedIn from '@mui/icons-material/LinkedIn';
import Email from '@mui/icons-material/Email';

import './footer.styles.scss';

const Footer = () => {
  return (
    <Fragment>
      <section className="footer-section">
        <div className="footer-icons">
          <a href="mailto:support@newdew.io" className="nav-link">
            <Email className="social-media email" />
          </a>
          <a
            href="http://www.linkedin.com/company/newdew"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram className="social-media instagram" />
          </a>
          <Link to="#">
            <Twitter className="social-media twitter" />
          </Link>
          <a
            href="http://www.linkedin.com/company/newdew"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedIn className="social-media linkedin" />
          </a>
        </div>
        <SectionNavigation />
      </section>
      <div className="footer-distributed">
        <div className="footer-left">
          <h3>
            <span>NEW DEW</span>
          </h3>

          <p className="footer-links">
            <Link className="nav-link" to="/">
              HOME
            </Link>

            <Link to="/posts">POSTS</Link>
            <Link to="/">ABOUT</Link>
            {/* <Link to="#">CONTACT</Link> */}
          </p>
          <p className="footer-company-name">newdew.io©2024</p>
        </div>

        <div className="footer-center">
          <div>
            <i className="fa fa-map-marker"></i>
            <p>
              <span>144 N. Bedford Ave</span> Williamsberg Brooklyn, New York
            </p>
          </div>

          <div>
            <i className="fa fa-phone"></i>
            <p>+1.646.926.6082‬</p>
          </div>

          <div>
            <i className="fa fa-envelope"></i>
            <p>
              <a href="mailto:support@newdew.io">support@newdew.io</a>
            </p>
          </div>
        </div>

        <div className="footer-right">
          <p className="footer-company-about">
            <span>About the company</span>
            Our passion is to share the knowledge and stories about products,
            hair, and the feels about so you can feel empowered and confident in
            how you wear your hair!
          </p>
        </div>
      </div>
    </Fragment>
  );
};
export default Footer;
