import React, { useState } from 'react';
import Product from '../Product/Product';
import Modal from './Modal';
import './styles.scss';

const ProductCardContainer = ({ product, title }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <main className="content">
      <div onClick={openModal}>
        <Product product={product} />
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        product={product}
        postCategory={title}
      />
    </main>
  );
};

export default ProductCardContainer;
