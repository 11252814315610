import React, { useContext } from 'react';
// import Button from '../button/button.component';
import { CartContext } from '../../contexts/cart.context';
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  IconButton,
} from '@mui/material';
import AddShoppingCart from '@mui/icons-material/AddShoppingCart'
import { parse } from 'node-html-parser';
import './styles.scss';

const Product = ({ product }) => {
  const { title, imageUrl, content } = product;
  const { addItemToCart } = useContext(CartContext);
  const addProductToCart = () => addItemToCart(product);
  //TEMP DESC
  product.description = 'Insert Item description here';


  const getFirstLine = (htmlContent) => {
    const root = parse(htmlContent);
    const firstParagraph = root.querySelector('p');
    return firstParagraph ? firstParagraph.textContent.split('\n')[0] : '';
  };

  const getFirstSentence = (htmlContent) => {
    const root = parse(htmlContent);
    const firstParagraph = root.querySelector('p');
    if (firstParagraph) {
      const firstSentence = firstParagraph.textContent.split('.')[0];
      return firstSentence.trim();
    }
    return '';
  };

  const getFirstWords = (htmlContent, wordLimit) => {
    const root = parse(htmlContent);
    const firstParagraph = root.querySelector('p');
    if (firstParagraph) {
      const words = firstParagraph.textContent.split(/\s+/);
      return words.slice(0, wordLimit).join(' ').trim();
    }
    return '';
  };


  const descriptor = getFirstWords(content, 30);
  return (
    <Card>
      <CardMedia className='media' image={imageUrl} title={title} />
      <div className='root'>
        <CardContent>
          <div className='cardContent'>
            <Typography gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
            {/* <Typography gutterBottom variant="h5" component="h2">
              ${price}
            </Typography> */}
          </div>
          <Typography
            dangerouslySetInnerHTML={{ __html: descriptor }}
            variant="body2"
            color="textSecondary"
            component="p"
          />
        </CardContent>
      </div>
    </Card>
  );
};

export default Product;
