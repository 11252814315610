import {
  Fragment,
  useContext,
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { Outlet, Link } from 'react-router-dom';
import logo from '../../assets/pickoutline.png';
import girltext from '../../assets/girl-text.png';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../contexts/user.context';
import { SignOutUser } from '../../utils/firebase/firebase.utils';
import CartDropdown from '../../components/cart-dropdown/cart-dropdown.component';
import Footer from '../../components/footer/footer.component';
import { FaAndroid, FaApple } from 'react-icons/fa'; // Importing icons
import './navigation.styles.scss';
import User from '../../assets/128x128.png';
import ModalOverlay from '../../routes/category/overlay';
import Modal from '../../components/product-card/Modal';
import { useParams } from 'react-router-dom';
import { CategoriesContext } from '../../contexts/categories.context';
import 'react-quill/dist/quill.snow.css';
import Button from '../../components/button/button.component';
// import './category.styles.scss';
import { useNavigate } from 'react-router-dom';
import Resizer from 'react-image-file-resizer';
import { AddPost, uploadCoverArt } from '../../utils/firebase/firebase.utils';
import { v4 } from 'uuid';

const Navigation = () => {
  const { currentUser } = useContext(UserContext);
  const [type, setType] = useState('');
  const location = useLocation();
  const currentUrl = location.pathname;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  ///////////////////////
  const navigate = useNavigate();
  const { category } = useParams();
  const { categoriesMap } = useContext(CategoriesContext);
  const [products, setProducts] = useState(categoriesMap[category]);
  const [categoryName, setCategoryName] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [isCropOpen, setIsCropOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const quillRef = useRef(null);
  const [coverArt, setCoverArt] = useState(null);
  const [file, setFile] = useState(null);
  const [showPost, setShowPost] = useState(false);
  const [justUploaded, setJustUploaded] = useState(null);
  // const [uploadModal, setUploadModal] = useState(false);

  const handleOpenModal = () => {
    if (currentUser) {
      setIsModalOpen(true);
    } else {
      navigate('/auth');
    }
  };
  const goToShop = () => navigate('/posts');
  const resetForm = () => {
    setCategoryName('');
    setIsModalOpen(false);
    setTitle('');
    setContent('');
    setIsCropOpen(false);
    setImageSrc(null);
    setCoverArt(null);

    // If you need to reset the Quill editor content
    if (quillRef.current) {
      quillRef.current.getEditor().setText('');
    }
  };

  const handleCloseModal = () => {
    resetForm();
    setIsModalOpen(false);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
        setIsCropOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCropComplete = (croppedImageUrl) => {
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection();
    quill.insertEmbed(range.index, 'image', croppedImageUrl);
    setIsCropOpen(false);
    setImageSrc(null);
  };

  const handleSubmit = async () => {
    console.log('currentUser:', categoryName);
    try {
      const downloadURL = await uploadCoverArt(file);
      console.log('File available at', categoryName);

      if (downloadURL) {
        const newestUpload = await AddPost(categoryName, {
          id: v4(),
          writerId: currentUser.id || currentUser.uid,
          title,
          content,
          username:
            currentUser.displayName ||
            currentUser.email.split('@')[0].charAt(0).toUpperCase() +
              currentUser.email.split('@')[0].slice(1).toLowerCase(),
          imageUrl: downloadURL,
        });
        handleCloseModal();
        setJustUploaded(newestUpload);
      }
    } catch (error) {
      console.error('Error uploading post:', error);
    }
  };

  const imageHandler = () => {
    document.getElementById('image-upload').click();
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: '1' }, { header: '2' }, { font: [] }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ align: [] }],
          [{ color: [] }, { background: [] }],
          ['clean'],
        ],
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'color',
    'background',
    'align',
  ];

  const MAX_SIZE_KB = 10;
  const INITIAL_WIDTH = 500;
  const INITIAL_HEIGHT = 500;
  const INITIAL_QUALITY = 100;
  const FORMAT = 'WEBP';

  const resizeFile = (file, width, height, quality) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        width,
        height,
        FORMAT,
        quality,
        0,
        (uri) => {
          fetch(uri)
            .then((response) => response.blob())
            .then((blob) => resolve({ uri, blob }))
            .catch((error) =>
              console.error('Error converting URI to Blob:', error)
            );
        },
        'base64'
      );
    });

  const compressImageToMaxSize = async (file) => {
    let width = INITIAL_WIDTH;
    let height = INITIAL_HEIGHT;
    let quality = INITIAL_QUALITY;

    while (true) {
      const { uri, blob } = await resizeFile(file, width, height, quality);
      const sizeKB = blob.size / 1024;

      if (sizeKB <= MAX_SIZE_KB) {
        return { uri, blob };
      }

      if (quality > 10) {
        quality -= 10; // Decrease quality in steps of 10
      } else if (width > 100 && height > 100) {
        width -= 50; // Decrease dimensions if quality is already low
        height -= 50;
      } else {
        console.error(
          'Unable to compress image below 10KB while preserving quality.'
        );
        return { uri: null, blob: null };
      }
    }
  };

  const handleFileChange = async (event) => {
    let file = event.target.files[0];
    const { uri: fileURI, blob: fileBlob } = await compressImageToMaxSize(file);

    if (fileURI && fileBlob) {
      const reader = new FileReader();
      reader.onload = () => {
        setCoverArt(reader.result);
        setFile(fileBlob); // Set blob version to state or wherever needed
      };
      reader.readAsDataURL(fileBlob); // Read blob version as data URL
    }
  };
  ///////////////////////////

  return (
    // <Fragment>
    //   {currentUrl == '/' && (
    //     <div className="banner">
    //       {isModalOpen && (
    //         <ModalOverlay
    //           setCatName={setCategoryName}
    //           title={title}
    //           handleTitleChange={handleTitleChange}
    //           coverArt={coverArt}
    //           handleFileChange={handleFileChange}
    //           content={content}
    //           handleContentChange={handleContentChange}
    //           modules={modules}
    //           formats={formats}
    //           quillRef={quillRef}
    //           handleImageUpload={handleImageUpload}
    //           handleSubmit={handleSubmit}
    //           handleCloseModal={handleCloseModal}
    //         />
    //       )}
    //       <p>App now in Beta please contact team to join test list</p>
    //     </div>
    //   )}

    //   <div className={`navigation ${currentUrl === '/' ? '' : 'away'}`}>
    //     <div className="container">
    //       {currentUser && (
    //         <div className="user-info">
    //           <img src={User} className="user" alt="User" />
    //           <span>
    //             {currentUser.displayName ||
    //               currentUser.email.split('@')[0].charAt(0).toUpperCase() +
    //                 currentUser.email.split('@')[0].slice(1).toLowerCase()}
    //           </span>
    //         </div>
    //       )}
    //       <Link className="logo-container" to="/">
    //         <div className="logo-hover-container">
    //           <img className="logo" src={logo} alt="Logo" />
    //         </div>
    //       </Link>
    //     </div>
    //     <div style={{ justifyContent: 'space-between' }}>
    //       <img src={girltext} className="centered-image" alt="Girl Text" />
    //       <div
    //         className="centered-image"
    //         style={{ position: 'relative', left: 20 }}
    //       >
    //         <label style={{ display: 'flex', alignItems: 'center' }}>
    //           <FaAndroid style={{ marginRight: '5px' }} />
    //           Android
    //         </label>
    //         <label style={{ display: 'flex', alignItems: 'center' }}>
    //           <FaApple style={{ marginRight: '5px' }} />
    //           iOS
    //         </label>
    //       </div>
    //     </div>

    //     <div className="nav-links-container">
    //       <Link className="nav-link" to="/join-mailing-list">
    //         JOIN TEST LIST
    //       </Link>
    //       <Link className="nav-link story" to="/" onClick={openModal}>
    //         <span
    //           style={{
    //             color: 'darkred',
    //             fontSize: '30px',
    //             textTransform: 'uppercase',
    //           }}
    //         >
    //           *
    //         </span>
    //         SHARE A STORY
    //         <span style={{ color: 'darkred', fontSize: '30px' }}>*</span>
    //       </Link>
    //       <Link className="nav-link" to="/posts">
    //         VIEW POSTS
    //       </Link>

    //       {currentUser ? (
    //         <Link className="nav-link" to="/" onClick={SignOutUser}>
    //           SIGN OUT
    //         </Link>
    //       ) : (
    //         <Link className="nav-link" to="/auth">
    //           SIGN IN
    //         </Link>
    //       )}
    //     </div>
    //   </div>

    //   {justUploaded && (
    //     <Modal
    //       isOpen={justUploaded}
    //       onClose={() => setJustUploaded(null)}
    //       product={justUploaded.articles[justUploaded.articles.length - 1]}
    //       postCategory={justUploaded.title}
    //     />
    //   )}

    //   <Outlet />
    //   <Footer />
    // </Fragment>
    <Fragment>
      {currentUrl === '/' && (
        <div className="banner">
          {isModalOpen && (
            <ModalOverlay
              setCatName={setCategoryName}
              title={title}
              handleTitleChange={handleTitleChange}
              coverArt={coverArt}
              handleFileChange={handleFileChange}
              content={content}
              handleContentChange={handleContentChange}
              modules={modules}
              formats={formats}
              quillRef={quillRef}
              handleImageUpload={handleImageUpload}
              handleSubmit={handleSubmit}
              handleCloseModal={handleCloseModal}
            />
          )}
          <p>App now in Beta, please contact the team to join the test list.</p>
        </div>
      )}

      <nav className={`navigation ${currentUrl === '/' ? '' : 'away'}`}>
        <div className="container">
          {currentUser && (
            <div className="user-info">
              <img src={User} className="user " alt="User" />
              <span>
                {currentUser.displayName ||
                  currentUser.email.split('@')[0].charAt(0).toUpperCase() +
                    currentUser.email.split('@')[0].slice(1).toLowerCase()}
              </span>
            </div>
          )}
          <Link className="logo-container" to="/">
            <div className="logo-hover-container">
              <img className="logo " src={logo} alt="Logo" />
            </div>
          </Link>
        </div>

        <div className="centered-images">
          <div className="device-labels">
            <label>
              <FaAndroid />
              Android
            </label>
            <label>
              <FaApple />
              iOS
            </label>
          </div>
          <img src={girltext} className="centered-image" alt="Girl Text" />
        </div>

        <div className="nav-links-container">
          <Link className="nav-link" to="/join-mailing-list">
            JOIN TEST LIST
          </Link>
          <Link className="nav-link story" to="/" onClick={openModal}>
            <span className="highlighted">*</span>
            SHARE A STORY
            <span className="highlighted">*</span>
          </Link>
          <Link className="nav-link" to="/posts">
            VIEW POSTS
          </Link>

          {currentUser ? (
            <Link className="nav-link" to="/" onClick={SignOutUser}>
              SIGN OUT
            </Link>
          ) : (
            <Link className="nav-link" to="/auth">
              SIGN IN
            </Link>
          )}
        </div>
      </nav>

      {justUploaded && (
        <Modal
          isOpen={justUploaded}
          onClose={() => setJustUploaded(null)}
          product={justUploaded.articles[justUploaded.articles.length - 1]}
          postCategory={justUploaded.title}
        />
      )}

      <Outlet />
      <Footer />
    </Fragment>
  );
};

export default Navigation;
