import React from 'react';
import DirectoryItem from '../directory-item/directory-item.component';
import './directory.styles.scss';

import coilsAndCurlsImage from '../../assets/lady-back.png';
import foodAndHairImage from '../../assets/foodandhair.avif';
import workAndHairImage from '../../assets/workandhaair.avif';
import homeGirlImage from '../../assets/momkidbraid.avif';
import miscImage from '../../assets/pexels-photo-4968954.jpeg';

const categories = [
  {
    id: 1,
    title: 'Coils and Curls',
    imageUrl: coilsAndCurlsImage,
    route: 'posts/coilsandcurls',
  },
  {
    id: 2,
    title: 'Food and Hair',
    imageUrl: foodAndHairImage,
    route: 'posts/foodandhair',
  },
  {
    id: 3,
    title: 'Work and Hair',
    imageUrl: workAndHairImage,
    route: 'posts/workandhair',
  },
  {
    id: 4,
    title: 'Home Girl',
    imageUrl: homeGirlImage,
    route: 'posts/homegirl',
  },
  {
    id: 5,
    title: 'Misc',
    imageUrl: miscImage,
    route: 'posts/misc',
  },
];

const Directory = () => {
  return (
    <div className="directory-container">
      {categories.map((category) => (
        <DirectoryItem
          key={category.id}
          category={category}
          categoryProducts={categories}
        />
      ))}
    </div>
  );
};

export default Directory;
