import React, { useState } from 'react';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import Share from '@mui/icons-material/ReplyAllOutlined';
import Link from '@mui/icons-material/Link';
import { Menu, MenuItem, IconButton } from '@mui/material';

const ShareCopyDropdown = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCopyUrl = () => {
        const currentUrl = window.location.href;
        navigator.clipboard.writeText(currentUrl).then(() => {
            alert('URL copied to clipboard!');
        }).catch((err) => {
            console.error('Failed to copy URL: ', err);
        });
        handleClose();
    };

    const handleShare = () => {
        // Add your share logic here
        alert('Share functionality not implemented yet');
        handleClose();
    };

    return (
        <div className="dropdown">
            <IconButton onClick={handleClick} className="dropbtn">
                <MoreIcon />
            </IconButton>
            <div className="dropdown-content">
                <MenuItem onClick={handleShare}>
                    <Share style={{ transform: 'scaleX(-1)' }} /> {"  "}Share
                </MenuItem>
                <MenuItem onClick={handleCopyUrl}>
                    <Link />
                    Copy
                </MenuItem>
            </div>
        </div>


    );
};

export default ShareCopyDropdown;





