// import { Fragment, useState, useContext } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { Typography } from '@mui/material';

// const PrivacyPolicy = () => {
//   return (
//     <Fragment>
//       <div>
//         <Typography>Privacy Policy for NEWDEW</Typography>
//         <p>Last Updated: 2024-06-01</p>

//         <h2>Information Collection and Use</h2>
//         <p>
//           This privacy policy applies to the NEWDEW app ("Application") for
//           mobile devices created by NDNC ("Service Provider") as a Free service.
//           This service is provided "AS IS".
//         </p>
//         <p>
//           NEWDEW Inc. ("NEWDEW", "we" or "us") provides this Privacy Policy to
//           inform users of our policies and procedures regarding the collection,
//           use and disclosure of personally identifiable information received
//           from users of this website (the "Website") and all other products and
//           services, including mobile applications, owned, controlled or offered
//           by NEWDEW, and all content offered as a part thereof (the "Services").
//           This Privacy Policy may be updated from time to time for any reason,
//           at our sole discretion. We will notify you of any material changes to
//           our Privacy Policy by posting the new Privacy Policy on our Website.
//           You are advised to consult this Privacy Policy regularly for any
//           changes. By using or accessing the Website, you are accepting the
//           practices described in this Privacy Policy, and you are consenting to
//           our processing of your information as set forth in this Privacy Policy
//           now and as amended by us.
//         </p>
//         <p>
//           If you have any questions or comments about this Privacy Policy or our
//           use of your personally identifiable information, please contact us at
//           <a href="mailto:newdewnow@gmail.com">newdewnow@gmail.com</a>.
//         </p>
//         <p>
//           <strong>Personal Data:</strong> We collect personal data from you, the
//           user, when you voluntarily choose to register with NEWDEW. We use the
//           personal data that we collect in order to provide and improve our
//           services, and as otherwise set forth in this Privacy Policy and in our
//           Terms and Conditions. Examples of personal data include first name,
//           last name, address, email address, telephone number, birth date,
//           billing and credit card information.
//         </p>
//         <p>
//           <strong>Third Party Personal Data:</strong> We also collect personal
//           data from you, the user, about certain third party individuals,
//           including but not limited to your clients. We use this personal data
//           that we collect from you in order to provide and improve our services,
//           facilitate communication between you and such third parties, and as
//           otherwise set forth in this Privacy Policy and in our Terms and
//           Conditions. You warrant to NEWDEW that any personal data you provide
//           us about any third party individuals was obtained by you with full
//           consent from such third party, and that such third party has not
//           communicated to you that they wish to opt out of receiving
//           communication from NEWDEW.
//         </p>
//         <p>
//           <strong>Use of Personal Data:</strong> NEWDEW uses the personal data
//           you provide in a manner that is consistent with this Privacy Policy or
//           with any applicable service-specific agreement. If you provide
//           personal data for a certain reason, we may use the personal data in
//           connection with the reason for which it was provided. For instance, we
//           will use the personal data you input during the registration process
//           to administer access to the Website. We may also use the client
//           information you submit to send such clients emails or SMS messages
//           when you take action via the Website to input them or at your
//           direction via the Website. We may also contact your clients to tell
//           them about products and services that you and other NEWDEW
//           professionals may offer and to further promote your business and the
//           Service. We will give your clients the opportunity to opt out of
//           receiving communications from NEWDEW. NEWDEW and its affiliates also
//           use your and your clients' personal data and other personally
//           non-identifiable information collected through the Website to help us
//           improve the content and functionality of the Website, to better
//           understand the users of our Website, and to improve our services.
//         </p>
//         <p>
//           <strong>Payment and Transactional Data:</strong> Payment and
//           transaction data is needed to complete your orders on the website or
//           through the Services (including name, payment card information, bank
//           account number, billing information) and your transaction history,
//           although NEWDEW does not have access to payment card numbers. Our
//           payment processors, Stripe, Square, and PayPal, will collect the
//           financial information necessary to process your payments in accordance
//           with their respective services agreement and privacy policy.
//         </p>
//         <p>
//           <strong>Personally Non-Identifiable Information:</strong> We may
//           collect personally non-identifiable information from you at the time
//           of registration, or when you choose to use certain of our services.
//           This information is not, by itself, sufficient to identify or contact
//           you or your clients. Examples include demographic data, age, education
//           level, profession, geographic location or gender. NEWDEW may store
//           such information, or it may be included in databases owned and
//           maintained by NEWDEW's affiliates, agents or service providers. This
//           Website may use such information and pool it with other information to
//           track, for example, the total number of visitors to our Website and
//           the domain names of our visitors' Internet service providers. It is
//           important to note that no personal data is available or used in this
//           process. When you interact with us through the Website, we receive and
//           store certain additional personally non-identifiable information. Such
//           information, which is collected passively using various technologies,
//           cannot presently be used to specifically identify you. Examples
//           include IP addresses, browser types, domain names, and other anonymous
//           statistical data involving your use of the Website and/or our
//           services.
//         </p>
//         <p>
//           <strong>Aggregated Personal Data:</strong> In an ongoing effort to
//           better understand our users, we might analyze the personal data that
//           is provided to the Website in aggregate form. This aggregate
//           information does not identify you or your clients personally. We may
//           share this aggregate data with our affiliates, agents and business
//           partners. We may also disclose aggregated user statistics in order to
//           describe our services to current and prospective business partners,
//           and to other third parties for other lawful purposes.
//         </p>
//         <p>
//           <strong>Cookies:</strong> In operating this Website, we may use a
//           technology called "cookies." A cookie is a piece of information that
//           the computer that hosts our Website gives to your computer when you
//           access a website. Our cookies help provide additional functionality to
//           the Website and help us analyze Website usage more accurately.
//         </p>
//         <p>
//           <strong>Sharing Information:</strong> We won't share you or your
//           clients' personal information with anyone except for the situations
//           listed below or unless we specifically inform you and give you an
//           opportunity to opt out of our sharing your personal information. We
//           may share your personal information with:
//         </p>
//         <ul>
//           <li>
//             Authorized service providers. Our authorized service providers are
//             companies that perform certain services on our behalf. These
//             services may include fulfilling orders, processing credit card
//             payments, delivering packages, providing customer service and
//             marketing assistance, performing business and sales analyses,
//             supporting the Website functionality, and supporting contests,
//             sweepstakes, surveys and other features we offer. These service
//             providers may have access to your clients' or your personal
//             information to the extent necessary to perform their functions, but
//             we do not allow them to share or use any of your personal
//             information for any other purpose.
//           </li>
//           <li>
//             NEWDEW affiliates. We may share information we collect, including
//             personal information, with NEWDEW-owned or affiliated businesses.
//             Sharing information with our affiliates enables us to provide you
//             with information about a variety of products and services that might
//             interest you. All NEWDEW companies comply with applicable privacy
//             and security laws and, at a minimum, in any commercial e-mail they
//             send to you, will give you the opportunity to choose not to receive
//             such e-mail messages in the future.
//           </li>
//           <li>
//             Business partners. We may share your clients or your personal
//             information with the merchants offering the products, services,
//             promotions, contests and/or sweepstakes. We won't share your
//             personal information with these businesses unless you choose to
//             participate in their offer or program. When you choose to engage in
//             a particular offer or program, you authorize us to share your email
//             address and other information with the relevant merchant.
//           </li>
//           <li>
//             Other Situations. We also may disclose your clients' or your
//             information:
//           </li>
//           <ul>
//             <li>
//               In response to a subpoena or similar investigative demand, a court
//               order, or a request for cooperation from a law enforcement or
//               other government agency; to establish or exercise our legal
//               rights; to defend against legal claims; or as otherwise required
//               by law. In such cases, we may raise or waive any legal objection
//               or right available to us.
//             </li>
//             <li>
//               When we believe disclosure is appropriate in connection with
//               efforts to investigate, prevent, or take other action regarding
//               illegal activity, suspected fraud or other wrongdoing; to protect
//               and defend the rights, property or safety of our company, our
//               users, our employees, or others; to comply with applicable law or
//               cooperate with law enforcement; or to enforce the Website's Terms
//               of Use or other agreements or policies.
//             </li>
//             <li>
//               In connection with a substantial corporate transaction, such as
//               the sale of our business, a divestiture, merger, consolidation, or
//               asset sale, or in the unlikely event of bankruptcy.
//             </li>
//           </ul>
//         </ul>
//         <p>
//           <strong>Analytics:</strong> We may use UXCam, which is an analytics
//           solution. UXCam may record: Screens visited, Interaction patterns
//           (such as screen actions, gestures: taps, scrolls) and Device details
//           (Type, Version, Model, Operating System). We are using the information
//           collected by UXCam to improve our app. UXCam does not collect
//           personally identifiable information and does not track your browsing
//           habits across apps. For more information see Privacy Policy for
//           Information Collected by the UXCam Service.
//         </p>
//         <p>
//           FullStory may collect information on your use of our app, such as
//           pages visited, links clicked, non-sensitive text entered, and mouse
//           movements, as well as information more commonly collected such as the
//           referring URL, browser, operating system, and Internet Protocol (“IP”)
//           address. FullStory uses publicly available sources to approximate your
//           geographic region and Internet Service Provider based on your IP
//           address. We are using the information collected by FullStory to
//           improve our app. For more information see Privacy Policy for
//           Information Collected by the FullStory Service.
//         </p>
//         <p>
//           <strong>Children:</strong> The Website and our related services are
//           not intended for children under 13, and we do not knowingly collect
//           information from children under the age of 13. Children aged 13 or
//           older should not submit any personal information without the
//           permission of their parents or guardians. By using the Website and our
//           related services, you are representing that you are at least 18.
//         </p>
//         <p>
//           <strong>Links to Other Websites:</strong> This Privacy Policy applies
//           only to this Website and not to any third-party sites.
//         </p>
//         <p>
//           <strong>Security:</strong> We take precautions to ensure the security
//           of your clients' and your personal information. However, we cannot
//           guarantee that hackers or unauthorized personnel will not gain access
//           to your personal information despite our efforts. You should note that
//           in using the Website and our related services, this information will
//           travel through third party infrastructures which are not under our
//           control. We cannot protect, nor does this Privacy Policy apply to, any
//           information that you transmit to other users of the Website. You
//           should never transmit personal or identifying information to other
//           users.
//         </p>
//         <p>
//           <strong>Opt-Out Policy:</strong> If, at any time after registering,
//           you change your mind about receiving information from us or about the
//           use of information volunteered by you, please send us a request
//           specifying your new choice. Please contact us as specified above.
//         </p>
//         <p>
//           <strong>Changes to the Privacy Policy:</strong> Although most changes
//           are likely to be minor, NEWDEW may change its Privacy Policy from time
//           to time, and in NEWDEW's sole discretion. NEWDEW encourages visitors
//           to frequently check this page for any changes to its Privacy Policy.
//           Your continued use of the Services after any change in this Privacy
//           Policy will constitute your acceptance of such change.
//         </p>
//         <p>
//           The Application collects information when you download and use it,
//           including but not limited to:
//         </p>
//         <ul>
//           <li>Your device's Internet Protocol address (IP address)</li>
//           <li>
//             Pages of the Application visited, time and date of visit, time spent
//             on pages
//           </li>
//           <li>Operating system used on your mobile device</li>
//           <li>Location data (ACCESS_COARSE_LOCATION, ACCESS_FINE_LOCATION)</li>
//           <li>Network information (ACCESS_NETWORK_STATE, ACCESS_WIFI_STATE)</li>
//           <li>Camera data (CAMERA)</li>
//           <li>Audio data (MODIFY_AUDIO_SETTINGS, RECORD_AUDIO)</li>
//           <li>
//             Storage access (READ_EXTERNAL_STORAGE, WRITE_EXTERNAL_STORAGE)
//           </li>
//           <li>
//             Device information (ACCESS_NETWORK_STATE, INTERNET,
//             RECEIVE_BOOT_COMPLETED, WAKE_LOCK)
//           </li>
//           <li>Activity recognition (ACTIVITY_RECOGNITION)</li>
//           <li>Notification permissions (NOTIFICATIONS, POST_NOTIFICATIONS)</li>
//           <li>System permissions (SYSTEM_ALERT_WINDOW)</li>
//           <li>
//             Badge permissions for launcher icons (READ_APP_BADGE,
//             WRITE_APP_BADGE)
//           </li>
//           <li>Other miscellaneous permissions</li>
//         </ul>

//         <h2>Information You Provide</h2>
//         <p>
//           <strong>Information You Provide:</strong> We may collect information
//           you provide when you interact with the App, such as when you create an
//           account, save preferences, or contact customer support.
//         </p>
//         <p>
//           <strong>Automatically Collected Information:</strong> We may
//           automatically collect certain information when you use the App, such
//           as your device type, operating system, IP address, and usage data.
//         </p>
//         <p>The data is collected for the following purposes:</p>
//         <ul>
//           <li>To provide location-based services</li>
//           <li>To improve network connectivity</li>
//           <li>To enable camera functionality</li>
//           <li>To record audio for specific features</li>
//           <li>To access and store files for app functionality</li>
//           <li>To analyze device information for optimization</li>
//           <li>To recognize user activities for app features</li>
//           <li>To deliver notifications</li>
//           <li>To provide system-level functionality</li>
//           <li>To display badges for app icons</li>
//           <li>
//             Other purposes related to app functionality and user experience
//           </li>
//         </ul>

//         <h2>How We Use Your Information</h2>
//         <ol>
//           <li>
//             <strong>Providing and Improving the App:</strong> We use the
//             information we collect to operate, maintain, and improve the App,
//             including to personalize your experience, analyze usage patterns,
//             and optimize performance.
//           </li>
//           <li>
//             <strong>Communicating with You:</strong> We may use your information
//             to communicate with you, such as to respond to your inquiries, send
//             important notices, or provide updates about the App.
//           </li>
//           <li>
//             <strong>Marketing and Advertising:</strong> With your consent, we
//             may use your information to send you promotional materials and
//             offers, or to deliver targeted advertisements that may be of
//             interest to you.
//           </li>
//           <li>Providing and improving app services</li>
//           <li>Enhancing user experience</li>
//           <li>Customizing content</li>
//           <li>Delivering targeted advertisements</li>
//           <li>Performing analytics</li>
//           <li>Facilitating communication</li>
//           <li>Ensuring app functionality</li>
//           <li>Complying with legal requirements</li>
//           <li>Other purposes necessary for app operation</li>
//         </ol>
//         <h2>User Controls</h2>
//         <p>Users have the following controls over their data:</p>
//         <ul>
//           <li>Ability to revoke specific permissions</li>
//           <li>Option to delete account and associated data</li>
//           <li>Contact information for privacy inquiries</li>
//         </ul>

//         <h2>Data Retention</h2>
//         <p>
//           The collected data is retained for as long as necessary to fulfill the
//           purposes outlined in this privacy policy. Users may request deletion
//           of their data by contacting us.
//         </p>

//         <h2>Third Party Access</h2>
//         <p>
//           Only aggregated, anonymized data is periodically transmitted to
//           external services to aid the Service Provider in improving the
//           Application and their service. The Service Provider may share your
//           information with third parties as described in this privacy statement.
//         </p>
//         <p>
//           Please note that the Application utilizes third-party services that
//           have their own Privacy Policy about handling data.
//         </p>
//         <ul>
//           <li>
//             <a
//               href="https://www.google.com/policies/privacy/"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               Google Play Services
//             </a>
//           </li>
//         </ul>

//         <h2>Your Consent</h2>
//         <p>
//           By using the Application, you consent to the processing of your
//           information as set forth in this Privacy Policy.
//         </p>
//         <p>
//           We implement appropriate security measures to protect against
//           unauthorized access, alteration, disclosure, or destruction of data.
//         </p>

//         <h2>Contact Us</h2>
//         <p>
//           If you have any questions regarding privacy while using the
//           Application, or have questions about the practices, please contact the
//           Service Provider via email at{' '}
//           <a href="mailto:newdewnow@gmail.com">newdewnow@gmail.com</a>.
//         </p>
//       </div>
//     </Fragment>
//   );
// };

// export default PrivacyPolicy;

import { Fragment, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Fragment>
      <div>
        <Typography variant="h4">Privacy Policy for NEWDEW</Typography>
        <p>Last Updated: 2024-09-01</p>

        <h2>Information Collection and Use</h2>
        <p>
          This Privacy Policy applies to the NEWDEW app ("Application") for
          mobile devices created by NDNC ("Service Provider") as a free service.
          This service is provided "AS IS".
        </p>
        <p>
          NEWDEW Inc. ("NEWDEW", "we" or "us") provides this Privacy Policy to
          inform users of our policies and procedures regarding the collection,
          use, and disclosure of personally identifiable information received
          from users of our website (the "Website") and all other products and
          services, including mobile applications, owned, controlled, or offered
          by NEWDEW, and all content offered as part of the services
          ("Services"). This Privacy Policy may be updated from time to time for
          any reason at our sole discretion. We will notify you of any material
          changes to our Privacy Policy by posting the new Privacy Policy on our
          Website. You are advised to consult this Privacy Policy regularly for
          any changes. By using or accessing the Website, you accept the
          practices described in this Privacy Policy and consent to our
          processing of your information as set forth in this Privacy Policy,
          now and as amended by us.
        </p>
        <p>
          If you have any questions or comments about this Privacy Policy or our
          use of your personally identifiable information, please contact us at
          <a href="mailto:bizdev@newdew.io ">bizdev@newdew.io</a>.
        </p>

        <h2>Personal Data</h2>
        <p>
          <strong>Personal Data:</strong> We collect personal data from you when
          you voluntarily choose to register with NEWDEW. We use the personal
          data we collect to provide and improve our services, and as otherwise
          set forth in this Privacy Policy and in our Terms and Conditions.
          Examples of personal data include first name, last name, address,
          email address, telephone number, birth date, and billing information.
        </p>
        <p>
          <strong>Third Party Personal Data:</strong> We also collect personal
          data from you about third-party individuals, including but not limited
          to your clients. We use this personal data to provide and improve our
          services, facilitate communication between you and third parties, and
          as set forth in this Privacy Policy and our Terms and Conditions. You
          warrant to NEWDEW that any personal data you provide about third-party
          individuals was obtained with full consent from such third parties and
          that they have not communicated to you a desire to opt out of
          receiving communication from NEWDEW.
        </p>

        <h2>Use of Personal Data</h2>
        <p>
          <strong>Use of Personal Data:</strong> NEWDEW uses the personal data
          you provide in a manner consistent with this Privacy Policy or any
          applicable service-specific agreement. If you provide personal data
          for a certain reason, we may use it in connection with the reason it
          was provided. For example, we will use the personal data you input
          during registration to administer access to the Website. We may also
          use the client information you submit to send them emails or SMS
          messages when you take action via the Website or at your direction via
          the Website. We may also contact your clients to inform them about
          products and services offered by you and other NEWDEW professionals
          and to promote your business and the Service. We will give your
          clients the opportunity to opt out of receiving communications from
          NEWDEW. NEWDEW and its affiliates also use your and your clients'
          personal data and other non-identifiable information collected through
          the Website to improve the content and functionality of the Website,
          better understand the users of our Website, and improve our services.
        </p>

        <h2>Payment and Transactional Data</h2>
        <p>
          <strong>Payment and Transactional Data:</strong> Payment and
          transaction data are needed to complete your orders on the Website or
          through the Services (including name, payment card information, bank
          account number, billing information, and transaction history).
          Although NEWDEW does not have access to payment card numbers, our
          payment processors (Stripe, Square, PayPal) will collect the financial
          information necessary to process your payments in accordance with
          their respective service agreements and privacy policies.
        </p>

        <h2>Personally Non-Identifiable Information</h2>
        <p>
          <strong>Personally Non-Identifiable Information:</strong> We may
          collect non-identifiable information from you at the time of
          registration or when you use certain of our services. This information
          alone is not sufficient to identify or contact you or your clients.
          Examples include demographic data, age, education level, profession,
          geographic location, or gender. NEWDEW may store this information or
          include it in databases maintained by our affiliates, agents, or
          service providers. We may use this information and pool it with other
          information to track, for example, the total number of visitors to our
          Website and the domain names of our visitors' Internet service
          providers. It is important to note that no personal data is available
          or used in this process. When you interact with us through the
          Website, we receive and store certain additional non-identifiable
          information, such as IP addresses, browser types, domain names, and
          other anonymous statistical data involving your use of the Website
          and/or our services.
        </p>

        <h2>Aggregated Personal Data</h2>
        <p>
          <strong>Aggregated Personal Data:</strong> In an ongoing effort to
          better understand our users, we might analyze the personal data
          provided to the Website in aggregate form. This aggregate information
          does not identify you or your clients personally. We may share this
          aggregate data with our affiliates, agents, and business partners. We
          may also disclose aggregated user statistics to describe our services
          to current and prospective business partners and other third parties
          for lawful purposes.
        </p>

        <h2>Cookies</h2>
        <p>
          <strong>Cookies:</strong> In operating this Website, we may use a
          technology called "cookies." A cookie is a piece of information that
          the computer that hosts our Website gives to your computer when you
          access the website. Our cookies help provide additional functionality
          to the Website and help us analyze Website usage more accurately.
        </p>

        <h2>Sharing Information</h2>
        <p>
          <strong>Sharing Information:</strong> We won't share your or your
          clients' personal information with anyone except for the situations
          listed below or unless we specifically inform you and give you an
          opportunity to opt out of our sharing your personal information. We
          may share your personal information with:
        </p>
        <ul>
          <li>
            Authorized service providers: Companies that perform certain
            services on our behalf, such as fulfilling orders, processing credit
            card payments, delivering packages, providing customer service,
            marketing assistance, performing business and sales analyses,
            supporting Website functionality, and supporting contests,
            sweepstakes, surveys, and other features we offer. These service
            providers may have access to your clients' or your personal
            information to the extent necessary to perform their functions, but
            we do not allow them to share or use any of your personal
            information for any other purpose.
          </li>
          <li>
            NEWDEW affiliates: We may share information we collect, including
            personal information, with NEWDEW-owned or affiliated businesses.
            Sharing information with our affiliates enables us to provide you
            with information about a variety of products and services that might
            interest you. All NEWDEW companies comply with applicable privacy
            and security laws and, at a minimum, in any commercial email they
            send to you, will give you the opportunity to opt out of receiving
            such messages in the future.
          </li>
          <li>
            Business partners: We may share your or your clients' personal
            information with merchants offering products, services, promotions,
            contests, and/or sweepstakes. We won't share your personal
            information with these businesses unless you choose to participate
            in their offer or program. When you choose to engage in a particular
            offer or program, you authorize us to share your email address and
            other information with the relevant merchant.
          </li>
          <li>
            Other situations: We may also disclose your or your clients'
            information in response to a subpoena or similar investigative
            demand, a court order, or a request for cooperation from a law
            enforcement or other government agency; to establish or exercise our
            legal rights; to defend against legal claims; or as otherwise
            required by law. In such cases, we may raise or waive any legal
            objection or right available to us.
          </li>
          <li>
            We may disclose information when we believe disclosure is
            appropriate in connection with efforts to investigate, prevent, or
            take other action regarding illegal activity, suspected fraud, or
            other wrongdoing; to protect and defend the rights, property, or
            safety of our company, our users, our employees, or others; to
            comply with applicable law or cooperate with law enforcement; or to
            enforce our Terms and Conditions or other agreements or policies.
          </li>
          <li>
            Business transfers: In connection with a substantial corporate
            transaction, such as the sale of our business, a divestiture,
            merger, consolidation, or asset sale, or in the unlikely event of
            bankruptcy, we may transfer your or your clients' information to the
            successor organization.
          </li>
        </ul>

        <h2>Data Security</h2>
        <p>
          <strong>Data Security:</strong> We take reasonable steps to protect
          the personal data provided via the Website from loss, misuse,
          unauthorized access, disclosure, alteration, or destruction. However,
          no Internet or email transmission is ever fully secure or error-free.
          In particular, email sent to or from the Website may not be secure.
          Therefore, you should take special care in deciding what information
          you send to us via email. Please keep this in mind when disclosing any
          personal data to NEWDEW via the Internet.
        </p>

        <h2>Children's Privacy</h2>
        <p>
          <strong>Children's Privacy:</strong> The Website and Services are not
          intended for children under the age of 13. We do not knowingly collect
          personal information from children under the age of 13. If we become
          aware that we have inadvertently received personal information from a
          user under the age of 13 as part of the Website or Services, we will
          delete such information from our records. If you are a parent or
          guardian and you believe that your child under the age of 13 has
          provided personal information through our Website or Services, please
          contact us at <a href="mailto:bizdev@newdew.io">bizdev@newdew.io</a>.
        </p>
        <h3>Account Deletion</h3>
        <p>
          If you wish to delete your account, please email us at
          <a href="mailto:bizdev@newdew.io">bizdev@newdew.io</a> with the
          following information:
        </p>
        <ul>
          <li>Account type: Client or Stylist</li>
          <li>Name (or Shop Name if Stylist)</li>
          <li>Phone Number</li>
        </ul>

        <h2>Changes to Our Privacy Policy</h2>
        <p>
          <strong>Changes to Our Privacy Policy:</strong> We may update this
          Privacy Policy to reflect changes to our information practices. If we
          make any material changes, we will notify you by email (sent to the
          email address specified in your account) or by means of a notice on
          this Website prior to the change becoming effective. We encourage you
          to periodically review this page for the latest information on our
          privacy practices.
        </p>

        <h2>Contact Information</h2>
        <p>
          <strong>Contact Information:</strong> If you have any questions or
          comments about this Privacy Policy, the practices of this Website, or
          your dealings with this Website, you can contact us at:
        </p>
        <p>
          Email: <a href="mailto:bizdev@newdew.io">bizdev@newdew.io</a>
        </p>

        <h2>Consent</h2>
        <p>
          <strong>Consent:</strong> By using our Website or Services, you
          consent to our Privacy Policy.
        </p>
        <h2>Governing Law</h2>
        <p>
          <strong>Governing Law:</strong> This Privacy Policy shall be governed
          by, and construed in accordance with, applicable laws where NEWDEW
          operates.
        </p>
      </div>
    </Fragment>
  );
};

export default PrivacyPolicy;
