import React from 'react';

interface AlertProps {
  subject: string;
  message: string;
  onClose: () => void;
}

const Alert: React.FC<AlertProps> = ({ subject, message, onClose }) => {
  return (
    <div className="flex items-start">
      <button
        onClick={() => onClose()}
        style={{
          display: 'flex',
          height: 60,
          width: 60,
          borderRadius: 30,
          float: 'inline-end',
          alignItems: 'normal',
          position: 'relative',
          bottom: 120,
          backgroundColor: 'orangered',
          opacity: 0.8,
        }}
      >
        <p className="sr-only">Close</p>
        <svg
          className="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
          style={{ color: '#fff' }}
        >
          <path
            fillRule="evenodd"
            d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414L11.414 10l2.293 2.293a1 1 0 11-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 10 6.293 7.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium text-gray-900">{subject}</p>
        <p className="mt-1 text-sm text-gray-500">{message}</p>
      </div>
    </div>
  );
};

export default Alert;
