// import { useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { CartContext } from '../../contexts/cart.context';
// import Button from '../button/button.component';
// import CartItem from '../cart-item/cart-item.component';
// import './cart-dropdown.styles.scss';
import qr from '../../assets/lnkedInQR.png';
// import newdewIcon from '../../assets/hairpick.png';
// import appStoreBadge from '../../assets/app-store-badge.svg';
// import playStoreBadge from '../../assets/g-128x128.png';
// import InstallQR from '../../components/install-qr/install-qr';

// const CartDropdown = ({ type }) => {
//   const navigate = useNavigate();
//   return (
//     <div className="cart-dropdown-container">
//       <div className="cart-items">
//         {type == 'InstallQR' && (
//           <div className="store-badges">
//             <img
//               src={appStoreBadge}
//               alt="App Store Badge"
//               className="store-badge"
//             />
//             <img
//               src={playStoreBadge}
//               alt="Play Store Badge"
//               className="store-badge"
//             />
//           </div>
//         )}
//         <img
//           src={qr}
//           style={{
//             marginBottom: type == 'InstallQR' ? 20 : 0,
//             position: 'relative',
//             bottom: '8px',
//             height: type == 'InstallQR' ? '9rem' : '12rem',
//             width: type == 'InstallQR' ? '9rem' : '12rem',
//             alignSelf: 'center',
//           }}
//           alt="app qr code"
//         />
//       </div>
//       {type == 'InstallQR' && (
//         <div style={{ height: '4rem', width: '9rem', alignSelf: 'center' }}>
//           <div>
//             <span
//               style={{ textAlign: 'center', position: 'relative', top: '25px' }}
//             >
//               New Dew App
//             </span>
//             <img
//               src={newdewIcon}
//               style={{
//                 height: 60,
//                 width: 60,
//                 alignSelf: 'center',
//                 position: 'absolute',
//               }}
//               alt="Icon"
//             />
//           </div>
//         </div>
//       )}
//       {type !== 'InstallQR' && (
//         <div style={{ alignSelf: 'center' }}>
//           <img
//             src={newdewIcon}
//             style={{
//               height: 60,
//               width: 60,
//               position: 'relative',
//               left: '50px',
//             }}
//             alt="Icon"
//           />
//           <Button disabled={true}>LinkedIn</Button>
//         </div>
//       )}
//     </div>
//   );
// };
// export default CartDropdown;

import { useState, useContext } from 'react';
import './cart-dropdown.styles.scss'; // Ensure this file contains the above CSS
import { CartContext } from '../../contexts/cart.context';
import Button from '../button/button.component';

const CartDropdown = () => {
  const { isCartOpen, setIsCartOpen, cartItems } = useContext(CartContext);

  const toggleCartOpen = () => {
    setIsCartOpen(!isCartOpen);
  };

  return (
    isCartOpen && (
      <div className="cart-dropdown-overlay" onClick={toggleCartOpen}>
        <div
          className="cart-dropdown-container"
          onClick={(e) => e.stopPropagation()}
        >
          {cartItems.length ? (
            <div className="cart-items">
              {cartItems.map((item) => (
                <div key={item.id}>{item.name}</div>
              ))}
            </div>
          ) : (
            <div className="empty-cart">
              <img src={qr} alt="Empty cart" />
              <span className="app-label">New Dew App</span>
            </div>
          )}
          <Button onClick={toggleCartOpen}>Close</Button>
        </div>
      </div>
    )
  );
};

export default CartDropdown;
