import { useContext } from 'react';
import JoinFullOutlined from '@mui/icons-material/QrCodeScannerOutlined';
import { CartContext } from '../../contexts/cart.context';
import './install-icon.styles.scss';

const InstallQR = ({ type }) => {
  const { isCartOpen, setIsCartOpen, cartCount } = useContext(CartContext);
  const toggleIsCartOpen = () => {
    if (!isCartOpen) {
      type('InstallQR');
    }

    setIsCartOpen(!isCartOpen);
  };

  return (
    <div className="qr-icon-container" onClick={toggleIsCartOpen}>
      <JoinFullOutlined className="shopping-icon" onClick={toggleIsCartOpen} />
    </div>
  );
};

export default InstallQR;
