import SHOP_DATA from '../../../shop-data';
import { Link, useNavigate } from 'react-router-dom';
import './section-navigation.styles.scss';

const SectionNavigation = () => {
  const navigate = useNavigate();

  const navigateToSection = ({ title }) => {
    const endPoint = title.replace(/ /g, '').toLocaleLowerCase();
    navigate(`/posts/${endPoint}`);
    window.scrollTo(0, 0);
  };
  return (
    <div className="float">
      <ul className="section-navigation-ul">
        {SHOP_DATA.map(({ title }) => {
          if (title === 'Work and Hair') {
            title = 'Work and Hair';
          }
          return (
            <li
              className="section-navigation-list"
              onClick={() => navigateToSection({ title })}
              key={title}
            >
              <Link to="">{title}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SectionNavigation;
