import React, { useState, useRef } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const ImageCropComponent = ({ src, onComplete }) => {
    const [crop, setCrop] = useState({
        unit: 'px',
        width: 200,
        height: 200,
        x: 0,
        y: 0,
    });
    const [completedCrop, setCompletedCrop] = useState(null);
    const imgRef = useRef(null);
    const [imageRef, setImageRef] = useState(null);

    const onImageLoaded = (image) => {
        const aspectCrop = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 50,
                },
                1, // aspect ratio
                image.width,
                image.height
            ),
            image.width,
            image.height
        );
        imgRef.current = image;
        setCrop(aspectCrop);
        setImageRef(image);
    };

    const onCropChange = (newCrop) => {
        setCrop(newCrop);
    };

    const onCropCompleteHandler = (crop) => {
        console.log('the crop ', imgRef.current, crop.width, crop.height);
        setCompletedCrop(crop);
        if (imgRef.current && crop.width && crop.height) {
            const canvas = document.createElement('canvas');
            const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
            const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext('2d');

            ctx.drawImage(
                imgRef.current,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );

            const croppedImageUrl = canvas.toDataURL('image/jpeg');
            onComplete(croppedImageUrl);
        }
    };

    const onCropComplete = (crop) => {
        setCompletedCrop(crop);
    };

    const getCroppedImg = (image, crop) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = 'cropped.jpeg';
                resolve(blob);
            }, 'image/jpeg');
        });
    };

    const handleCropComplete = async () => {
        if (completedCrop && imgRef) {
            const croppedImage = await getCroppedImg(imgRef, completedCrop);
            onComplete(URL.createObjectURL(croppedImage));
        }


        else {
            console.log('no');
        }
    };

    return (
        <div>
            {src && (
                <ReactCrop
                    crop={crop}
                    onChange={onCropChange}
                    onComplete={onCropCompleteHandler}
                >
                    <img
                        src={src}
                        style={{ height: '60%', width: '60%', margin: '0 auto' }}
                        ref={imgRef}
                        alt="Crop"
                        onLoad={() => onImageLoaded(imgRef.current)}
                    />
                </ReactCrop>
            )}
            {/* <br />
            <button onClick={onCropCompleteHandler}>Crop</button> */}
        </div>
    );
};

export default ImageCropComponent;










