// import { Fragment, useState, useContext } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import Button from '../button/button.component';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import './home-splashpage.styles.scss';
// import CartDropdown from '../../components/cart-dropdown/cart-dropdown.component';
// import { renameDocument } from '../../utils/firebase/firebase.utils';
// import { CartContext } from '../../contexts/cart.context';
// import { Typography } from '@mui/material';
// import InstallQR from '../../components/install-qr/install-qr';

// const SplashPage = () => {
//   const navigate = useNavigate();
//   const goToShop = () => navigate('/posts');
//   const [type, setType] = useState('');
//   const { isCartOpen } = useContext(CartContext);
//   return (
//     <Fragment>
//       <div className="big-image">
//         <div className="overlay">
//           <Typography variant="p" component="span">
//             Find your Hairstyle
//           </Typography>
//           <h1>NEWDEW</h1>
//           <div className="bannerTitle">
//             <Typography>
//               {' '}
//               Try Trending Braids,Locs,Crochets in Realtime with a Click
//             </Typography>
//             <InstallQR type={setType} />
//           </div>
//         </div>
//         {isCartOpen && <CartDropdown type={type} />}
//       </div>

//       <div id="vids">
//         <h3>
//           Our promise is to help you create a hair routine that is minimal,
//           comfy and effortless much like the Hygge.
//         </h3>
//         <a
//           href="https://www.newyorker.com/culture/culture-desk/the-year-of-hygge-the-danish-obsession-with-getting-cozy"
//           target="_blank"
//         >
//           <p>
//             Find out More
//             <ArrowForwardIcon className="arrow" />
//           </p>
//         </a>
//       </div>
//     </Fragment>
//   );
// };

// export default SplashPage;

import { Fragment, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../button/button.component';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './home-splashpage.styles.scss';
import CartDropdown from '../../components/cart-dropdown/cart-dropdown.component';
import { renameDocument } from '../../utils/firebase/firebase.utils';
import { CartContext } from '../../contexts/cart.context';
import { Typography } from '@mui/material';
import InstallQR from '../../components/install-qr/install-qr';

const SplashPage = () => {
  const navigate = useNavigate();
  const goToShop = () => navigate('/posts');
  const [type, setType] = useState('');
  const { isCartOpen } = useContext(CartContext);
  return (
    <Fragment>
      <div className="big-image">
        <div className="overlay">
          <Typography variant="p" component="span">
            The Wind in Your Hair
          </Typography>
          <h1>NEWDEW</h1>
          <div className="bannerTitle">
            <Typography>
              {' '}
              Try Trending Braids,Locs,Crochets in Realtime with a Click
            </Typography>
            <InstallQR type={setType} />
          </div>
        </div>
        {isCartOpen && <CartDropdown type={type} />}
      </div>

      <div id="vids">
        <h3>
          Our promise is to help you create a hair routine that is minimal,
          comfy and effortless much like the Hygge.
        </h3>
        <a
          href="https://www.newyorker.com/culture/culture-desk/the-year-of-hygge-the-danish-obsession-with-getting-cozy"
          target="_blank"
        >
          <p>
            Find out More
            <ArrowForwardIcon className="arrow" />
          </p>
        </a>
      </div>
    </Fragment>
  );
};

export default SplashPage;
